var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live-rank"},[_c('div',{staticClass:"live-rank__title"},[_vm._v(" 直播排行 ")]),_c('div',{staticClass:"live-rank__content"},[(_vm.data.length > 0)?_c('div',{staticClass:"live-rank-list"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"live-rank-item",class:{
          'live-rank-item--1': index === 0,
          'live-rank-item--2': index === 1,
          'live-rank-item--3': index === 2
        }},[_c('div',{staticClass:"live-info"},[_c('div',{staticClass:"live-info__cover"},[_c('img',{attrs:{"src":item.cover,"alt":""}})]),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"user-info-avatar"},[_c('img',{attrs:{"src":item.author.avatar,"alt":""}})]),_c('div',{staticClass:"user-info-name"},[_vm._v(" "+_vm._s(item.author.nickname)+" ")])]),_c('div',{staticClass:"rank-num",class:{
              'rank-num--1': index === 0,
              'rank-num--2': index === 1,
              'rank-num--3': index === 2
            }},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('div',{staticClass:"live-data"},[_c('div',{staticClass:"data",class:{
              'data--1': index === 0,
              'data--2': index === 1,
              'data--3': index === 2
            }},[_c('div',{staticClass:"data-num"},[_vm._v(" "+_vm._s(_vm._f("changeNumNew")(item.statistics_total_watch_user_time))+" ")]),_c('div',{staticClass:"data-suffix"},[_vm._v(" "+_vm._s(_vm._f("generateNumSuffix")(item.statistics_total_watch_user_time))+" ")])]),_c('div',{staticClass:"graph",class:{
              'graph--1': index === 0,
              'graph--2': index === 1,
              'graph--3': index === 2
            }},[_c('div',{staticClass:"graph-top"}),_c('div',{staticClass:"graph-bottom"})])])])}),0):_c('div',{staticClass:"empty-wrap"},[_c('empty')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }