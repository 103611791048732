<template>
  <div
    class="img-container"
    :style="{
      'background-image': `url(${require('../../assets/default/default'+scale+'.png')})`,
      'min-width': width,
      'width': width,
      'min-height': height,
      'height': height,
      'border-radius': radius,
    }"
  >
    <img
      :src="currentSrc"
      @error="isBind && noFind()"
      :style="{
        'background-image': `url(${require('../../assets/default/default'+scale+'.png')})`,
        'border-radius': radius,
      }"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSrc: require(`../../assets/default/default${this.scale}.png`),
      isBind: true,
    };
  },
  props: {
    src: {
      type: String,
      default: require(`../../assets/default/default1-1.png`),
    },
    // 比例 '1-1'  '3-4'  '9-16'
    scale: {
      type: String,
      default: "1-1",
    },
    width: {
      type: String,
      default: "68px",
    },
    height: {
      type: String,
      default: "68px",
    },
    radius: {
      type: String,
      default: "4px",
    }
  },
  created() {
    // console.log('created', this.src)
    if (this.src) {
      this.currentSrc = this.src;
    } else {
      this.currentSrc = require(`../../assets/default/default${this.scale}.png`);
    }
  },
  watch: {
    src: {
      handler(val) {
        // console.log("src get", val);
        if (val) {
          this.currentSrc = val;
        } else {
          this.currentSrc = require(`../../assets/default/default${this.scale}.png`);
        }
      },
    },
  },
  methods: {
    noFind() {
      this.$nextTick(() => {
        // console.log("img error");
        let img = document.querySelector("img");
        // var img = event;
        // console.log(img)
        // console.log(img.style)
        this.currentSrc = require(`../../assets/default/default${this.scale}.png`);
        // img.style.visibility = "hidden"
        this.isBind = false; // 控制不要循环展示错误
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  // width: 100%;
  // height: 100%;
  display: inline-block;
  // background-image: url('../../assets/default/default1-1.png');
  background-size: 100% 100%;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    vertical-align: top;
  }
}
</style>