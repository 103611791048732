<template>
  <div class="live-rank">
    <div class="live-rank__title">
      直播排行
    </div>
    <div class="live-rank__content">
      <div class="live-rank-list" v-if="data.length > 0">
        <div
          class="live-rank-item"
          v-for="(item, index) in data"
          :key="index"
          :class="{
            'live-rank-item--1': index === 0,
            'live-rank-item--2': index === 1,
            'live-rank-item--3': index === 2
          }"
        >
          <div class="live-info">
            <div class="live-info__cover">
              <img :src="item.cover" alt="">
            </div>
            <div class="user-info">
              <div class="user-info-avatar">
                <img :src="item.author.avatar" alt="">
              </div>
              <div class="user-info-name">
                {{ item.author.nickname }}  
              </div> 
            </div>
            <div
              class="rank-num"
              :class="{
                'rank-num--1': index === 0,
                'rank-num--2': index === 1,
                'rank-num--3': index === 2
              }"
            >
              {{ index + 1 }}
            </div>
          </div>
          <div class="live-data">
            <div
              class="data"
              :class="{
                'data--1': index === 0,
                'data--2': index === 1,
                'data--3': index === 2
              }"
            >
              <div class="data-num">
                {{ item.statistics_total_watch_user_time | changeNumNew }}
              </div>
              <div class="data-suffix">
                {{ item.statistics_total_watch_user_time | generateNumSuffix }}
              </div>
            </div>
            <div
              class="graph"
              :class="{
                'graph--1': index === 0,
                'graph--2': index === 1,
                'graph--3': index === 2
              }"
            >
              <div class="graph-top"></div>
              <div class="graph-bottom"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-wrap" v-else>
        <empty />
      </div>
    </div>
  </div>  
</template>

<script>
import Empty from '@/components/Empty/Empty'

export default {
  name: 'LiveRank',
  components: {
    Empty  
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    }
  }  
}
</script>

<style lang="scss" scoped>
.live-rank {
  &__title {
    padding: 20px 0;
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
  }

  &__content {
    padding: 9px;
    height: 357px;
    background: #242832;
    border-radius: 16px;
  }
}

.live-rank-list {
  display: flex;
  padding: 36px 0 34px;
}

.live-rank-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &--1 {
    margin: 0 0 0 17px;
    width: 134px;
    order: 2;
  }

  &--2 {
    padding: 10px 0 0;
    width: 108px;
    order: 1;
  }

  &--3 {
    margin: 0 0 0 17px;
    padding: 10px 0 0;
    width: 108px;
    order: 3;
  }
}

.live-info {
  position: relative;
  width: 84px;

  &__cover {
    width: 84px;
    height: 148px;
    background: #000;
    border-radius: 8px;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    margin: 10px 0 0;

    &-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 6px 0 0;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-name {
      flex: 1;
      color: #FFF;
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .rank-num {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 18px;
    color: #292322;
    font-size: 10px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 18px;
    text-align: center;
    background: #FFC05C;
    border-radius: 0px 8px 0px 8px;

    &--1 {
      background: #FFC05C;
    }

    &--2 {
      background: #8BAFFE;
    }

    &--3 {
      background: #F5A679;
    }
  }
}

.live-data {
  width: 100%;
}

.data {
  display: flex;
  justify-content: center;

  &-num {
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 23px;
  }

  &-suffix {
    font-size: 20px;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 23px;
  }

  &--1 {
    padding: 28px 0 0;

    .data-num {
      color: #FFCA5C;
    }

    .data-suffix {
      color: #FFCA5C;
    }
  }

  &--2 {
    padding: 24px 0 0;

    .data-num {
      color: #8BAFFE;
    }

    .data-suffix {
      color: #8BAFFE;
    }
  }

  &--3 {
    padding: 29px 0 0;

    .data-num {
      color: #F5A679;
    }

    .data-suffix {
      color: #F5A679;
    }
  }
}

.graph {
  display: flex;
  flex-direction: column;
  margin: 12px 0 0;

  &-top {
    border-radius: 16px 16px 0 0;
  }

  &-bottom {
    margin: 1 0 0;
    border-radius: 0 0 16px 16px;
  }

  &--1 {
    .graph-top {
      height: 20px;
      background: #FFCA5C;
    }

    .graph-bottom {
      height: 20px;
      background: rgba(#FFCA5C, .04)
    }
  }

  &--2 {
    .graph-top {
      height: 14px;
      background: #8BAFFE;
    }

    .graph-bottom {
      height: 14px;
      background: rgba(#8BAFFE, .04)
    }
  }

  &--3 {
    .graph-top {
      height: 10px;
      background: #F5A679;
    }

    .graph-bottom {
      height: 10px;
      background: rgba(#F5A679, .04)
    }
  }
}

.empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
