<template>
  <div class="live-date">
    <div class="live-date__head">
      <div class="live-date__head-left">
        <span class="date">{{ date | formatDate('D') }}日</span>
        <span class="live-count">({{ liveCount }}场直播)</span>
      </div>
      <div class="live-date__head-right">
        <div class="week">{{week}}</div>
      </div>
    </div>
    <div class="live-date__body">
      <div class="timeline">
        <div class="timeline__head">全天</div>
        <div class="timeline__body beauty-scroll" id="content">
          <div
            class="timeline-item"
            v-for="dateTimeItem in dateTimeList"
            :key="dateTimeItem.dateTime"
            :id="dateTimeItem.dateTime"
          >
            <div class="timeline-item__time">
              {{ dateTimeItem.dateTime | formatTime }}
            </div>
            <div class="timeline-item__content">
              <div class="line"></div>
              <div class="live-card-list">
                <div
                  :class="`live-card ${isLiving(liveItem)}`"
                  v-for="(liveItem, liveItemIndex) in dateTimeItem.data"
                  :key="liveItemIndex"
                >
                  <div class="live-card-head">
                    <template v-if="liveItem.room_status === 2">
                      <div class="time">
                        <div class="icon-living">
                          <div class="icon-living-item"></div>
                          <div class="icon-living-item"></div>
                          <div class="icon-living-item"></div>
                        </div>
                        正在直播 {{ liveItem.publish_time | formatTime }}
                      </div>
                      <div class="time">
                        直播时长 {{ liveItem.statistics_live_time | formatDuration }}
                      </div>
                    </template>
                    <template v-if="liveItem.room_status === 4">
                      <div class="time">
                        开播 {{ liveItem.publish_time | formatTime }}
                      </div>
                      <div class="time">
                        下播 {{ liveItem.finish_time | formatTime }}
                      </div>
                    </template>
                  </div>
                  <div class="live-card-body">
                      <div class="avatar">
                        <img :src="liveItem.author.avatar" alt="">
                      </div>
                      <div class="content">
                        <div class="name">{{ liveItem.author.nickname }}</div>
                        <div class="duration">
                          直播时长：{{ liveItem.statistics_live_time | formatDuration }}
                        </div>
                      </div>
                      <div class="count">
                        <span class="icon-eye"></span>
                        <span class="num">{{ liveItem.statistics_total_watch_user_time | changeNumNew }}</span>
                        <span class="suffix">{{ liveItem.statistics_total_watch_user_time | generateNumSuffix }}</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>  
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'LiveDate',
  props: {
    date: {
      type: String,
      default: dayjs().format('YYYY-MM-DD')
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      dateTimeList: [],
      currDateTimeItemIndex: '',
      currLiveItemIndex: ''
    }
  },
  computed: {
    liveCount () {
      return this.data.length
    },
    week () {
      const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']

      return weeks[dayjs(this.date).day()]
    }
  },
  watch: {
    data: {
      deep: true,
      handler (newVal, oldVal) {
        this.createDateTimeList()
      }
    }
  },
  filters: {
    formatTime (val) {
      return dayjs(val).format('HH:mm')
    },
    formatDate (val, format = 'YYYY-MM-DD') {
      return dayjs(val).format(format)
    }
  },
  methods: {
    createDateTimeList () {
      let dateTimeList = []

      for (let i = 0; i <= 23 ; i++) {
        dateTimeList.push({
          dateTime: `${this.date} ${i < 10 ? ('0' + i) : i}:00`,
          data: []
        })

        dateTimeList.push({
          dateTime: `${this.date} ${i < 10 ? ('0' + i) : i}:30`,
          data: []
        })
      }

      this.data.forEach(liveItem => {
        dateTimeList.forEach(dateTimeItem => {
          if (
            dayjs(liveItem.publish_time) - dayjs(dateTimeItem.dateTime) >= 0 &&
            dayjs(liveItem.publish_time) - dayjs(dateTimeItem.dateTime) < 30 * 60 * 1000
          ) {
            dateTimeItem.data.push(liveItem)
          }
        })
      })

      this.dateTimeList = dateTimeList

      this.$nextTick(() => {
        const eleCon = document.getElementById('content')
        const index = this.dateTimeList.findIndex(item => item.data.length > 0)

        eleCon.scrollTop = 0
        
        if (index > -1) {
          const ele = document.getElementById(this.dateTimeList[index].dateTime)

          eleCon.scrollTop = ele.getBoundingClientRect().y - eleCon.getBoundingClientRect().y
        } else {
          eleCon.scrollTop = 0
        }
      })
    },
    isLiving (liveItem) {
      if (liveItem.room_status === 2) {
        return 'is-living'
      } else {
        return ''
      }
    },
    isActive (dateTimeItemIndex, liveItemIndex) {
      if (
        dateTimeItemIndex === this.currDateTimeItemIndex &&
        liveItemIndex === this.currLiveItemIndex
      ) {
        return 'is-active'  
      } else {
        return ''
      }
    },
    handleLiveDotClick (dateTimeItemIndex, liveItemIndex) {
      this.currDateTimeItemIndex = dateTimeItemIndex
      this.currLiveItemIndex = liveItemIndex
      this.$emit('setPageTimer')
    },
    handleVisibleChange (val) {
      console.log(val)
      if (!val) {
        this.currDateTimeItemIndex = ''
        this.currLiveItemIndex = ''
      }
    }
  },
  created () {
    this.createDateTimeList()
  }
}
</script>

<style lang="scss" scoped>
.live-date {
  height: 100%;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 34px 0 31px;
    height: 90px;
    
    .date {
      color: #FFCA5C;
      font-size: 24px;
      font-weight: bold;
    }

    .live-count {
      margin: 0 0 0 5px;
      color: #8890A6;
      font-size: 16px;
      font-weight: 400;
    }

    .week {
      color: #8890A6;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  &__body {
    padding: 0 0 26px;
    height: calc(100% - 90px);
  }
}

.timeline {
  height: 100%; 

  &__head {
    display: flex;
    align-items: center;
    padding: 0 0 0 31px;
    height: 29px;
    color: #8890A6;
    font-size: 14px;          
    background: #323744;
  }

  &__body {
    height: calc(100% - 29px);
    padding: 9px 0 0 0;
    overflow: auto;
  }
}

.timeline-item {
  display: flex;
  // height: 48px;

  &__time {
    display: flex;
    align-items: center;
    padding: 0 26px 0 35px;
    height: 100%;
    color: #565D72;
    font-size: 12px;
  }

  &__content {
    // display: flex;
    // align-items: center;
    position: relative;
    padding: 9px 0 0;
    flex: 1;
    height: 100%;

    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 50%;
    //   right: 0;
    //   left: 0;
    //   z-index: 0;
    //   height: 1px;
    //   background: #363A46;
    //   transform: translate(0, -50%);
    // }

    .line {
      height: 1px;
      background: #363A46;  
    }

    &.is-active {
      &::before {
        background: #FFCA5C;
      }
    }
  }
}

.live-dot-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 0 0 70px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  // &::before {
  //   display: block;
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   right: 0;
  //   left: 0;
  //   z-index: 0;
  //   height: 1px;
  //   background: #363A46;
  //   transform: translate(0, -50%);
  // }

  .live-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #242832;
    border: 2px solid #505666;
    border-radius: 50%;
  }

  &.is-living {
    .live-dot {
      border-color: #FFCA5C;
    }
  }

  &.is-active {
    .live-dot {
      background: #505666;
    }

    &.is-living {
      .live-dot {
        background: #FFCA5C;
      }
    }
  }
}

.live-card-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 20px;
}
.live-card {
  margin: 20px 20px 0 0;
  padding: 12px 14px 14px;
  width: 330px;
  background: #2B2F3B;
  border-radius: 16px;
  box-shadow: 0px 16px 10px 0px rgba(20, 22, 28, 0.1);

  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time {
      display: flex;
      align-items: center;
      color: #8890A6;
      font-size: 12px;
    }
  }

  &-body {
    display: flex;
    align-items: center;
    margin: 11px 0 0;

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 24px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      flex: 1;
      margin: 0 14px;
    }

    .name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #FFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .duration {
      margin: 3px 0 0;
      color: #9CA8BA;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
    }

    .count {
      display: flex;
      align-items: center;
    }

    .icon-eye {
      display: inline-block;
      margin: 0 4px 0 0;
      width: 22px;
      height: 22px;
      background: url("../../images/icon_eye.png") center no-repeat;
      background-size: cover;
      overflow: hidden;
    }

    .num {
      color: #FFF;
      font-size: 16px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }

    .suffix {
      color: #FFF;
      font-weight: normal;
    }
  }

  &.is-living {
    background: #FFCA5C;

    .live-card-head {
      .time {
        color: #5D4218;
      }
    }

    .live-card-body {
      .name {
        color: #000;
      }

      .duration {
        color: #48320F;
      }

      .icon-eye {
        background-image: url("../../images/icon_eye_2.png");
      }

      .num {
        color: #000;
      }

      .suffix {
        color: #000;
      }
    }
  }
}

.icon-living {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 8px 0 0;
  width: 8px;
  height: 9px;
  
  &-item {
    display: inline-block;
    width: 2px;
    background: #000;
    animation: move 1s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes move {
  0%,
  100% {
    height: 2px;
  }

  50% {
    height: 100%;
  }
}
</style>
