<template>
  <div class="container">
    <platform-header
      title="直播日历"
      :tabData="platList"
      @tabChange="handlePlatChange"
    />
    <section class="content-layout">
      <aside class="content-layout__aside">
        <live-calendar
          v-model="selectedDate"
          :data="liveCalendarData"
          @change="handleDateChange"
        />
        <live-rank
          :data="liveRankTop3List"
        />
      </aside>
      <main class="content-layout__main">
        <live-date
          :date="selectedDate"
          :data="selectedDateData"
          @setPageTimer="setPageTimer"
        />
      </main>
    </section>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import PlatformHeader from '@/components/PlatformTab/PlatformHeader'
import LiveCalendar from './components/LiveCalendar'
import LiveDate from './components/LiveDate'
import LiveRank from './components/LiveRank'
import { platList } from './constant'
import api from '@/api/liveCalendar'

export default {
  name: 'BrandCalendar',
  components: {
    PlatformHeader,
    LiveCalendar,
    LiveDate,
    LiveRank
  },
  data () {
    return {
      platList,
      currPlat: 'douyin',
      selectedDate: dayjs().format('YYYY-MM-DD'),
      lastSelectedMonth: dayjs().format('YYYY-MM'), // 记录上次已选日期的月份
      liveCalendarData: {},
      selectedDateData: [],
      liveRankTop3List: [],
      pageRouterTimer: null // 页面跳转定时器-5分钟
    }
  },
  created () {
    this.setPageTimer()
    this.getLiveCalendar()
    this.getLiveRankTop3()
  },
  methods: {
    //
    async getLiveCalendar () {
      const month = dayjs(this.selectedDate).format('YYYY-MM')
      const daysInMonth = dayjs(month).daysInMonth()
      try {
        const params = {
          platform: this.currPlat,
          // 以下是接口参数
          start_time: `${month}-01 00:00:00`,
          end_time: `${month}-${daysInMonth} 23:59:59`
        }
        const res = await api.getLiveCalendar(params)
        const { code, data, msg } = res.data

        if (code === 0) {
          this.liveCalendarData = data || {}
          this.getSelectedDateData()
        } else {
          console.log(msg)
          this.liveCalendarData = {}
          this.selectedDateData = []
        }
      } catch (e) {
        console.log(e)
      }
    },
    //
    async getLiveRankTop3 () {
      try {
        const month = dayjs(this.selectedDate).format('YYYY-MM')
        const daysInMonth = dayjs(month).daysInMonth()
        const params = {
          platform: this.currPlat,
          // 以下是接口参数
          start_time: `${month}-01 00:00:00`,
          end_time: `${month}-${daysInMonth} 23:59:59`
        }
        const res = await api.getLiveRankTop3(params)
        const { code, data, msg } = res.data
        if (code === 0) {
          this.liveRankTop3List = data || []
        } else {
          console.log(msg)
          this.liveRankTop3List = []
        } 
      } catch (e) {
        console.log(e)
      }
    },
    // 获取已选日期数据
    getSelectedDateData () {
      const keys = Object.keys(this.liveCalendarData)
      const index = keys.findIndex(key => key === this.selectedDate)

      if (index > -1) {
        this.selectedDateData = this.liveCalendarData[keys[index]]
      } else {
        this.selectedDateData = []
      }
    },
    // 处理平台切换
    handlePlatChange (val) {
      this.setPageTimer()
      this.currPlat = val
      this.getLiveCalendar()
      this.getLiveRankTop3()
    },
    //
    handleDateChange (val) {
      this.setPageTimer()

      const selectedMonth = dayjs(val).format('YYYY-MM')

      if (selectedMonth === this.lastSelectedMonth) {
        this.getSelectedDateData()
      } else {
        this.getLiveCalendar()
        this.getLiveRankTop3()
      }

      this.lastSelectedMonth = selectedMonth
    },
    // 重置定时器
    setPageTimer() {
      console.log('pageRouterTimer')
      clearTimeout(this.pageRouterTimer)
      this.pageRouterTimer = null;
      this.pageRouterTimer = setTimeout(() => {
        this.pageRouter();
      }, 1000 * 20);
    },
    // 定时路由跳转
    pageRouter() {
      clearTimeout(this.pageRouterTimer)
      this.pageRouterTimer = null;
      this.$router.push(`/bcCoreScreen/worksList`);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.container {
  padding: 0 0 50px;
}

.content-layout {
  display: flex;
  margin: 0 auto;
  width: 1800px;
  height: 898px;
  background: #2B2F3B;
  border-radius: 26px;

  &__aside {
    padding: 20px 16px 17px;
    width: 434px;
    height: 100%;
  }

  &__main {
    width: 1366px;
    height: 100%;
    background: #242832;
    border-radius: 26px;
  }
}
</style>
