<template>
  <div class="live-calendar">
    <div class="live-calendar__head">
      <a class="btn-prev" @click="handlePrevBtn">
        <span class="icon-arrow-left"></span>
      </a>
      <div class="text-month">{{ selectedMonth }}</div>
      <a class="btn-next" @click="handleNextBtn">
        <span class="icon-arrow-right"></span>
      </a>
    </div>
    <div class="live-calendar__body">
      <a-calendar
        :value="selectedDate"
        :fullscreen="false"
        valueFormat="YYYY-MM-DD"
        :header-render="headerRender"
        @select="handleSelect"
      >
        <template slot="dateCellRender" slot-scope="value">
          <span class="dot" v-if="hasLive(value)"></span>
          <div class="icon-living" v-if="hasLiveToday(value)">
            <div class="icon-living-item"></div>
            <div class="icon-living-item"></div>
            <div class="icon-living-item"></div>
          </div>
        </template>
      </a-calendar>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'LiveCalendar',
  props: {
    value: {
      type: String,
      default: dayjs().format('YYYY-MM-DD')
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      selectedDate: dayjs().format('YYYY-MM-DD')
    }
  },
  computed: {
    selectedMonth () {
      return dayjs(this.selectedDate).format('YYYY年MM月')
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.selectedDate = newVal
      }
    }
  },
  methods: {
    //
    headerRender({ value, type, onChange, onTypeChange }) {
      return ''
    },
    //
    hasLive (value) {
      const date = value.format('YYYY-MM-DD')
      const keys = Object.keys(this.data)

      if (keys.includes(date)) {
        return true
      } else {
        return false
      }
    },
    //
    hasLiveToday (value) {
      const date = value.format('YYYY-MM-DD')
      const currDate = dayjs().format('YYYY-MM-DD')
      const keys = Object.keys(this.data)
      const keyIndex = keys.findIndex(key => key === date)
      let isLiving = false

      if (keyIndex > -1) {
        const itemIndex = this.data[keys[keyIndex]].findIndex(item => item.room_status === 2)

        if (itemIndex > -1) {
          isLiving = true
        }
      }

      if (date === currDate && date === this.selectedDate && isLiving) {
        return true
      } else {
        return false
      }
    },
    // 上一个月
    handlePrevBtn () {
      const currMonth = dayjs().format('YYYY-MM')
      const prevMonth = dayjs(this.selectedDate).subtract(1, 'month').format('YYYY-MM')

      if (prevMonth === currMonth) {
        this.selectedDate = dayjs().format('YYYY-MM-DD')
      } else {
        this.selectedDate = `${prevMonth}-01`
      }

      this.$emit('input', this.selectedDate)
      this.$emit('change', this.selectedDate)
    },
    // 下一个月
    handleNextBtn () {
      const currMonth = dayjs().format('YYYY-MM')
      const nextMonth = dayjs(this.selectedDate).add(1, 'month').format('YYYY-MM')

      if (nextMonth === currMonth) {
        this.selectedDate = dayjs().format('YYYY-MM-DD')
      } else {
        this.selectedDate = `${nextMonth}-01`
      }

      this.$emit('input', this.selectedDate)
      this.$emit('change', this.selectedDate)
    },
    //
    handleSelect (date) {
      this.$emit('input', date)
      this.$emit('change', date)
    }
  }
}
</script>

<style lang="scss" scoped>
.live-calendar {
  &__head {
    display: flex;
    align-items: center;
    height: 36px;
  }

  .btn-prev, .btn-next {
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #323744;
    border-radius: 8px;

    .icon-arrow-left, .icon-arrow-right {
      display: inline-block;
      width: 6px;
      height: 10px;
      overflow: hidden;
    }

    .icon-arrow-left {
      background: url("../../images/icon_arrow_left.png") center no-repeat;
      background-size: cover;
    }

    .icon-arrow-right {
      background: url("../../images/icon_arrow_right.png") center no-repeat;
      background-size: cover;
    }

    &:hover {
      background: #3C4252;

      .icon-arrow-left {
        background: url("../../images/icon_arrow_left2.png") center no-repeat;
        background-size: cover;
      }

      .icon-arrow-right {
        background: url("../../images/icon_arrow_right2.png") center no-repeat;
        background-size: cover;
      }
    }
  }
  
  .text-month {
    flex: 1;
    height: 36px;
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 36px;
  }

  &__body {
    margin: 37px 0 0;
  }

  /deep/ .ant-fullcalendar {
    color: #565D72;
    font-size: 12px;
    font-weight: bold;
    border-top: none;

    &-column-header {
      &-inner {
        padding: 0 0 17px;
        height: 31px;
        line-height: 14px;
      }
    }

    &-calendar-body {
      padding: 0
    }

    &-date {
      position: relative;
      margin: 0 2px 2px;
      height: 53px;
      background: transparent;
      border-radius: 12px;
    }

    .ant-fullcalendar-tbody {
      tr:first {
        .ant-fullcalendar-date {
          margin-top: 0;
        }
      }
    }

    &-value {
      position: relative;
      z-index: 1;
      margin: 0;
      padding: 21px 0 0;
      width: 100%;
      color: #FFF;
      font-size: 14px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 14px;
      text-align: center;

      &:hover {
        background: transparent;
      }
    }

    &-content {
      bottom: 0;
      z-index: 0;
      height: 100%;
    }

    .dot {
      display: inline-block;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 6px;
      height: 6px;
      background: #FFCA5C;
      border-radius: 3px;
    }
  }

  /deep/ .ant-fullcalendar-selected-day {
    .ant-fullcalendar-date {
      // width: 53px;
      // height: 53px;
      background: #FFCA5C;
      border-radius: 12px;
    }

    .ant-fullcalendar-value {
      padding: 12px 0 0;
      color: #000;
      font-size: 24px;
      line-height: 24px;
      background: transparent;
      box-shadow: 0 0 0 0 transparent inset;
    }
  }

  /deep/ .ant-fullcalendar-today {
    .ant-fullcalendar-value {
      box-shadow: 0 0 0 0 transparent inset;  
    }
  }

  /deep/ .ant-fullcalendar-last-month-cell {
    .ant-fullcalendar-value {
      color: #3C4252;
    }
  }

  /deep/ .ant-fullcalendar-next-month-btn-day {
    .ant-fullcalendar-value {
      color: #3C4252;
    }
  }
}

.icon-living {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 6px;
  left: 50%;
  width: 8px;
  height: 9px;
  transform: translate(-50%, 0);
  
  &-item {
    display: inline-block;
    width: 2px;
    background: #000;
    animation: move 1s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes move {
  0%,
  100% {
    height: 2px;
  }

  50% {
    height: 100%;
  }
}
</style>
