<template>
  <div class="brand_rectangle">
    <a-spin :spinning="areaLoading" class="spin-class"/>

    <div class="title-div">
      <div>
        <svg-icon icon-class="area-title" class="fs22" />
        <span class="title">正在直播</span>
      </div>
      <!-- <div @click="routerTo" class="route-div">
        <span class="route">直播地图</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div> -->
    </div>
      <div class="top">
        <div class="top-left">
          <svg-icon icon-class="block" class="fs32" />
          <div>
            <span class="f14 fw fc21">{{ date }}</span>
            <br />
            <span class="f18 fw fc26">{{ time }}</span>
          </div>
        </div>
        <div class="top-right">
          <!-- <div class="arrow-btn" @click="arrowBtnClick('left')">
            <a-icon type="left" class="f12" @click="arrowBtnClick('left')" />
          </div> -->
          <div class="top-scroll" v-if="mapData">
            <div class="item" :style="{ left: topCardLeft + 'px' }">
              <span class="f12 fc22">正在直播门店</span>
              <span class="f16 fw fc26">{{
                mapData.living_count | changeNum
              }}</span>
            </div>
            <div class="item" :style="{ left: topCardLeft + 'px' }">
              <span class="f12 fc22">全网正在直播</span>
              <span class="f16 fw fc20">{{
                mapData.all_living_count | changeNum
              }}</span>
            </div>
            <div class="item" :style="{ left: topCardLeft + 'px' }">
              <span class="f12 fc22">今日直播门店</span>
              <span class="f16 fw fc26">{{
                mapData.live_count | changeNum
              }}</span>
            </div>
            <div class="item" :style="{ left: topCardLeft + 'px' }">
              <span class="f12 fc22">全网今日直播</span>
              <span class="f16 fw fc20">{{
                mapData.all_live_count | changeNum
              }}</span>
            </div>
            <div class="item" :style="{ left: topCardLeft + 'px' }">
              <span class="f12 fc22">今日累计观看</span>
              <span class="f16 fw fc26">{{
                mapData.live_total_watch_count | changeNum
              }}</span>
            </div>
            <div class="item" :style="{ left: topCardLeft + 'px' }">
              <span class="f12 fc22">全网累计观看</span>
              <span class="f16 fw fc20">{{
                mapData.all_live_total_watch_count | changeNum
              }}</span>
            </div>
          </div>
          <!-- <div class="arrow-btn" @click="arrowBtnClick('right')">
            <a-icon type="right" class="f12" @click="arrowBtnClick('right')" />
          </div> -->
        </div>
      </div>
      <div id="map"></div>

    <div class="live-list">
      <div class="live-list-title">
        <div>
          <svg-icon
            icon-class="living"
            class="fs24 mr6"
          />
          <span class="fc20 f14">正在直播</span>

          <Tooltip>
            <span>抖音、懂车帝展示累计观众</span>
            <br />
            <span>快手展示实时观众</span>
          </Tooltip>
        </div>
        <div>
          <!-- <svg-icon
            icon-class="refresh"
            style="font-size: 16px; margin-right: 14px"
            @click="initData('map')"
          />
          <span class="live-arrow" @click="routerTo">
            <svg-icon icon-class="arrow-right" style="font-size: 24px" />
          </span> -->
        </div>
      </div>
      <div class="live-main">
        <div
          class="live-item"
          v-for="(item, index) in mapData.data || []"
          :key="index"
        >
          <div>
            <svg-icon
              icon-class="fire"
              class="fs16 mr4"
              style="color: #EE4545;"
            />
            <span class="f12 fc21">{{ item.name }}</span>
          </div>
          <div>
            <svg-icon
              icon-class="area-watching"
              style="font-size: 22px; margin-right: 4px"
            />
            <span class="f12 fc21">{{ item.value | changeNum }}</span>
          </div>
        </div>
        <Empty style="margin-top: 70px;" :title="'暂无直播'" v-if="!mapData.data || mapData.data.length == 0"/>
      </div>
    </div>
    <div class="champion-card" v-if="$parent.principal_id == ''">
      <div
        :class="
          currentHover == 1 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 1"
        v-show="championsData.live && championsData.live.author_id"
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div class="img-box">
              <WebpImage 
                :src="championsData.live.cover" 
                :scale="'3-4'"
                :width="'47px'" 
                :height="'62px'" 
                :radius="'4px'" 
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" class="fs18" />
              </div>
            </div>
            <div>
              <span class="f16 fc26">单场直播冠军</span>
              <span class="f12 fc27" v-if="championsData && championsData.live && championsData.live.defending_champion_count">已卫冕{{ championsData.live.defending_champion_count || "-" }}周</span>
              <span class="f12 fc27" v-else>本周加冕</span>
              <div>
                <svg-icon icon-class="eye2" class="fs22" />
                <span class="f16 fc20">{{
                  championsData.live.watch_count | changeNum
                }}</span>
                <Tooltip :title="tooltipTitle1" />
              </div>
            </div>
            <svg-icon icon-class="champion" class="fs30" />
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>开播日期</span>
              <span>{{ championsData.live.publish_time || "-" }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.live.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          currentHover == 2 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 2"
        v-show="championsData.aweme && championsData.aweme.author_id"
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div class="img-box">
              <WebpImage 
                :src="championsData.aweme.aweme_cover" 
                :scale="'3-4'"
                :width="'47px'" 
                :height="'62px'" 
                :radius="'4px'" 
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <span class="f16 fc26">视频播放冠军</span>
              <span class="f12 fc27" v-if="championsData && championsData.aweme && championsData.aweme.defending_champion_count">已卫冕{{ championsData.aweme.defending_champion_count || "-" }}周</span>
              <span class="f12 fc27" v-else>本周加冕</span>
              <div>
                <svg-icon icon-class="eye2" style="font-size: 22px" />
                <span class="f16 fc20">{{
                  championsData.aweme.play_volume_count | changeNum
                }}</span>
                <Tooltip :title="tooltipTitle2" />
              </div>
            </div>
            <svg-icon icon-class="champion" class="fs30" />
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>发布日期</span>
              <span>{{ championsData.aweme.publish_time || "-" }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.aweme.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          currentHover == 3 ? 'champion-card-item open' : 'champion-card-item'
        "
        @mouseenter="currentHover = 3"
        v-show="championsData.market_value && championsData.market_value.author_id"
      >
        <div class="champion-card-item-main">
          <div class="champion-card-item-main-top">
            <div class="img-box hidden">
              <WebpImage 
                :src="championsData.market_value.avatar" 
                :scale="'1-1'"
                :width="'47px'" 
                :height="'47px'" 
                :radius="'4px'" 
              />
              <div class="img-shadow">
                <svg-icon icon-class="play3" style="font-size: 18px" />
              </div>
            </div>
            <div>
              <span class="f16 fc26">营销价值冠军</span>
              <span class="f12 fc27" v-if="championsData && championsData.market_value && championsData.market_value.defending_champion_count">已卫冕{{ championsData.market_value.defending_champion_count || "-" }}天</span>
              <span class="f12 fc27" v-else>今日加冕</span>
              <div>
                <span class="f16 fc20"
                  >¥{{
                    championsData.market_value.market_value | changeNum
                  }}</span
                >
                <Tooltip :title="tooltipTitle3" />
              </div>
            </div>
            <svg-icon icon-class="champion" class="fs30" />
          </div>
          <div class="champion-card-item-main-bottom">
            <div class="info-item">
              <span>直播&视频</span>
              <span>{{ championsData.market_value.aweme_live_count }}</span>
            </div>
            <div class="info-item">
              <span>账号等级</span>
              <span>{{ championsData.market_value.level }}</span>
            </div>
            <div class="info-item">
              <span>主播账号</span>
              <span>{{ championsData.market_value.nickname || "-" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as vuex from "vuex";
import echarts from "echarts";
import china from "echarts/map/json/china.json";
import { getPrincipalId } from "@/utils/auth";
import Tooltip from "@/components/Tooltip/Tooltip"
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty"
// series
import { symbol } from "../series/series.js";

const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardArea",
  components: {
    Tooltip,
    WebpImage,
    Empty
  },
  data() {
    return {
      currentHover: 1,
      tooltipTitle1: "累计总数据",
      tooltipTitle2: "累计总数据",
      tooltipTitle3: "本月数据",
      topCardLeft: 0,
      currentVideoLength: 0,
      date: moment().format("YYYY-MM-DD"), // 当前日期
      time: moment().format("HH:mm"), // 当前时间
      currentTimer: null, // 当前时间定时器
      currentMapIndex: -1,
      geoCenter: [117.114129, 37.550339],
      geoZoom: 1,
    };
  },
  watch: {
    mapData() {
      this.initChart();
      if (this.mapData.live_online_dealer_info_pos.length > 0) {
      } else {
        this.$message.warning("当前暂无直播，请稍后刷新页面重试");
      }
    },
  },
  computed: {
    ...mapState({
      mapData: (state) => state.mapData, // 地图数据
      championsData: (state) => state.championsData, // 冠军数据
      areaLoading: (state) => state.areaLoading,
    }),
  },
  created() {
    this.initData();
  },
  mounted() {
    // 设置video宽高
    window.onresize = () => {
      return (() => {
        this.chart.resize();
      })();
    };

    this.setCurrentTimer();
  },
  methods: {
    ...mapActions(["getMapData", "getChampions"]),
    ...mapMutations(["changeState"]),
    initData(type) {
      let params = {
        principal_id: this.$parent.principal_id || getPrincipalId(),
        platform: this.$parent.currentTab,
      };
      if (type == "map") {
        this.getMapData(params);
      } else {
        this.getMapData(params);
        if (this.$parent.principal_id == "") {
          this.getChampions(params);
        }
      }
    },
    // 直播地图顶部点击滚动
    arrowBtnClick(type) {
      if (type == "left") {
        if (this.topCardLeft >= -140) {
          this.topCardLeft = 0;
        } else {
          this.topCardLeft += 140;
        }
      } else {
        if (this.topCardLeft <= -4 * 140) {
          this.topCardLeft = -5 * 140;
        } else {
          this.topCardLeft -= 140;
        }
      }
    },
    routerTo() {
      this.$router.push({
        path: "/liveAndVideo/liveArea",
      });
    },
    // 地图加载
    initChart() {
      this.chart = echarts.init(document.getElementById("map"));
      echarts.registerMap("china", china);

      var geoCoordMap = this.mapData.geoCoordMap;
      var data = this.mapData.data || [];
      data.forEach((item, index) => {
        item.authorId =
          this.mapData.live_online_dealer_info_pos[index].author_id;
        item.roomId = this.mapData.live_online_dealer_info_pos[index].room_id;
      });

      /*获取地图数据*/
      var mapName = "china";
      // mychart.showLoading();
      var mapFeatures = echarts.getMap(mapName).geoJson.features;
      // mychart.hideLoading();
      mapFeatures.forEach(function (v) {
        // 地区名称
        var name = v.properties.name;
        // 地区经纬度
        geoCoordMap[name] = v.properties.cp;
      });

      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              cover: data[i].cover,
              authorId: data[i].authorId,
              roomId: data[i].roomId,
              index: i,
            });
          }
        }
        return res;
      };

      var option = {
        backgroundColor: "#2c2f3b",
        title: {
          top: 20,
          text: "",
          subtext: "",
          x: "center",
          textStyle: {
            color: "#ccc",
          },
        },

        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (!params.value[2] && !params.value) {
              return "";
            }
            if (typeof params.value[2] == "undefined") {
              // return params.name + " : " + params.value;
            } else {
              return `<div style="background: #fff;padding: 6px 30px;border-radius: 15px;position: relative;"><img src='${params.data.cover}' width='38' height='38' style="border-radius: 50%;position:absolute;left: -16px;top: -2px;border: 1px solid #fff;" />${params.name}</div>`;
              // return params.name + " : " + params.value[2];
            }
          },
          backgroundColor: "rgba(37, 50, 85, 0)",
          textStyle: {
            color: "#1C1E27",
          },
        },
        visualMap: {
          show: false,
          min: 0,
          max: 500,
          left: "left",
          top: "bottom",
          text: ["高", "低"], // 文本，默认为数值文本
          calculable: true,
          seriesIndex: [1],
          inRange: {},
        },
        geo: {
          map: "china",
          show: true,
          roam: false,
          zoom: this.geoZoom,
          center: this.geoCenter,
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              borderColor: "#FFCA5C",
              borderWidth: 1,
              areaColor: "#1C1E27",
            },
            emphasis: {
              areaColor: "rgba(0,0,0,0)", //悬浮区背景
            },
          },
        },
        series: [
          // 标记
          symbol(convertData(data)),
        ],
      };
      this.chart.off("click");
      this.chart.on("click", (params) => {
        // console.log(345, params);
      });
      this.chart.setOption(option);
    },
    // 当前时间更新
    setCurrentTimer() {
      this.currentTimer = setInterval(() => {
        this.date = moment().format("YYYY-MM-DD");
        this.time = moment().format("HH:mm");
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.currentTimer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fs22 {
  font-size: 22px;
}
.fs24 {
  font-size: 24px;
}
.fs32 {
  font-size: 32px;
}
.fs30 {
  font-size: 30px;
}
.mr4 {
  margin-right: 4px;
}
.mr6 {
  margin-right: 6px;
}
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.brand_rectangle {
  width: 100%;
  height: 100%;
  @include background_color("background_color2");
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 380px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .title {
      @include font_color("font_color20");
      font-size: 16px;
      margin-left: 10px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      .route {
        @include font_color("font_color28");
        font-size: 12px;
      }
      .arrow {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 10px;
        @include background_color("background_color5");
        @include font_color("font_color22");
        border-radius: 4px;
        transition: all 0.5s;
      }
    }
    .route-div:hover {
      .arrow {
        @include background_color("background_color1");
        @include font_color("font_color20");
      }
    }
  }

  .top {
    position: absolute;
    top: 72px;
    left: 20px;
    z-index: 99;
    width: 100%;
    height: 66px;
    display: flex;
    flex-direction: row;

    &-left {
      width: 130px;
      height: 100%;
      border-radius: 8px;
      display: inline-block;
      margin-right: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      width: calc(100% - 486px);
      height: 100%;
      border-radius: 8px;
      font-size: 25px;
      display: inline-block;
      display: flex;
      justify-content: space-between;
      position: relative;
      max-width: 884px;
      overflow: hidden;
      .arrow-btn {
        width: 22px;
        height: 100%;
        @include background_color("background_color5");
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include font_color("font_color22");
        transition: all 0.3s;
      }
      .arrow-btn:hover {
        @include background_color("background_color1");
        @include font_color("font_color20");
      }
      .top-scroll {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 52px);
        height: 82px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;

        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 14px 0;
          @include background_color("background_color70");
          margin-right: 10px;
          border-radius: 8px;
          width: 130px;
          height: 66px;
          min-width: 130px;
          position: relative;
          transition: all 0.3s;
        }
        .item:last-child {
          margin: 0;
        }
      }
    }
  }

  #map {
    width: 100%;
    height: calc(100% - 72px);
  }

  .live-list {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 290px;
    height: 458px;
    border-radius: 8px;
    @include background_color("background_color70");
    z-index: 999;
    &-title {
      width: 100%;
      height: 44px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 0 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      @include border_color("border_color13");
      > div {
        display: flex;
        align-items: center;
      }
      > div:last-child {
        cursor: pointer;
      }
      .live-arrow {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 10px;
        @include background_color("background_color5");
        @include font_color("font_color22");
        border-radius: 4px;
        transition: all 0.5s;
      }

      .live-arrow:hover {
        @include background_color("background_color1");
        @include font_color("font_color20");
      }
    }
    .live-main {
      width: 100%;
      height: 400px;
      overflow-y: auto;

      .live-item {
        padding: 0 20px;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .champion-card {
    position: absolute;
    top: 498px;
    right: 20px;
    width: 290px;
    height: 378px;
    border-radius: 8px;
    z-index: 999;
    &-item {
      width: 290px;
      height: 86px;
      border-radius: 8px;
      @include background_color("background_color70");
      transition: all 0.3s;
      margin-bottom: 10px;
      overflow-y: hidden;
      cursor: pointer;

      &-main {
        width: 100%;
        height: 100%;
        &-top {
          width: 100%;
          height: 86px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 12px 8px 13px 12px;
          > .img-box:hover {
            > .img-shadow {
              display: flex;
            }
          }
          > .img-box.hidden:hover {
            > .img-shadow {
              display: none;
            }
          }
          > .img-box {
            width: 47px;
            height: 62px;
            border-radius: 4px;
            margin-right: 20px;
            position: relative;
            > .img-shadow {
              width: 47px;
              height: 62px;
              @include background_color("background_color56");
              position: absolute;
              top: 0;
              left: 0;
              align-items: center;
              justify-content: center;
              display: none;
            }
          }
          > div {
            width: 160px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: 10px;
            > div {
              display: flex;
              align-items: center;
            }
          }
        }
        &-bottom {
          width: 100%;
          height: 100px;
          border-top: 1px solid rgba(0, 0, 0, 0);
          @include border_color("border_color13");
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 10px 0;
          .info-item {
            font-size: 14px;
            padding: 0 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span:first-child {
              @include font_color("font_color22");
            }
            span:last-child {
              @include font_color("font_color20");
            }
          }
        }
      }
    }
    &-item.open {
      height: 186px;
    }
  }
}
.a-notification__title {
  color: #4b53fc !important;
}
.a-notification__content {
  color: #0a2250 !important;
}
.fw {
  font-weight: bold;
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
.fc20 {
  @include font_color("font_color20");
}
.fc21 {
  @include font_color("font_color21");
}
.fc22 {
  @include font_color("font_color22");
}
.fc26 {
  @include font_color("font_color26");
}
.fc27 {
  @include font_color("font_color27");
}
.bc1 {
  @include background_color("background_color1");
}
.bc5 {
  @include background_color("background_color5");
}
</style>
