var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('a-spin',{staticClass:"spin-class",attrs:{"spinning":_vm.rankingLoading}}),_c('div',{staticClass:"title-div"},[_c('div',[_c('svg-icon',{staticClass:"fs22",attrs:{"icon-class":"brand-ranking"}}),_c('span',{staticClass:"title"},[_vm._v("品牌排名")]),_c('Tooltip',{attrs:{"title":_vm.tooltipTitle}})],1),_c('div',{staticClass:"info-div-hover"},[_vm._v(" "+_vm._s(_vm.$parent.currentTab == "douyin" ? "抖音" : _vm.$parent.currentTab == "kuaishou" ? "快手" : _vm.$parent.currentTab == "dongchedi" ? "懂车帝" : "")+"平台数据 ")])]),(_vm.rankingList)?_c('div',{staticClass:"info-div"},[_c('WebpImage',{attrs:{"src":_vm.rankingList.avatar ? _vm.ossHost + _vm.rankingList.avatar : '',"width":'68px',"height":'68px',"radius":'16px'}}),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"font1"},[_vm._v("粉丝总数")]),_c('div',{staticClass:"font2"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.rankingList.fans_count)))])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"font1"},[_vm._v("直播观看")]),_c('div',{staticClass:"font2"},[_vm._v(" "+_vm._s(_vm._f("changeNum")(_vm.rankingList.live_total_watch_user_time))+" ")])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"font1"},[_vm._v("营销价值")]),_c('div',{staticClass:"font2"},[_vm._v("¥"+_vm._s(_vm._f("changeNum")(_vm.rankingList.marketing_value)))])])],1):_vm._e(),(_vm.rankingList)?_c('a-table',{staticClass:"table-style",attrs:{"columns":_vm.columns,"data-source":_vm.rankingList.list,"rowKey":(data) => data.title,"pagination":false,"locale":{ emptyText: '暂无数据' }},scopedSlots:_vm._u([{key:"ranking",fn:function(scope){return _c('div',{staticClass:"td-style td-left ranking"},[_c('span',{class:scope.ranking == 1
            ? 'bc1'
            : scope.ranking == 2
            ? 'bc2'
            : scope.ranking == 3
            ? 'bc3'
            : 'bc',staticStyle:{"margin":"0 12px 0 20px"}},[_vm._v(_vm._s(scope.ranking))]),(scope.ranking_over_last_month > 0 && scope.ranking < 1000)?_c('svg-icon',{staticStyle:{"font-size":"12px"},attrs:{"icon-class":"new-up"}}):_vm._e(),(scope.ranking_over_last_month < 0 && scope.ranking < 1000)?_c('svg-icon',{staticStyle:{"font-size":"12px"},attrs:{"icon-class":"new-down"}}):_vm._e(),(scope.ranking_over_last_month && scope.ranking < 1000)?_c('span',{staticClass:"font-color23"},[_vm._v(_vm._s(Math.abs(scope.ranking_over_last_month) || ""))]):_vm._e()],1)}},{key:"name",fn:function(scope){return _c('div',{staticClass:"td-style"},[_c('span',{class:scope.ranking == 1
            ? 'table-name ranking-color1'
            : scope.ranking == 2
            ? 'table-name ranking-color2'
            : scope.ranking == 3
            ? 'table-name ranking-color3'
            : 'table-name'},[_vm._v(_vm._s(scope.title))])])}}],null,false,2280940163)},[_c('div',{staticStyle:{"padding-left":"20px"},attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('div',[_vm._v("排名")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }