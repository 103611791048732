<template>
  <div class="component-wrap">
    <a-spin :spinning="rankingLoading" class="spin-class" />
    <div class="title-div">
      <div>
        <svg-icon icon-class="brand-ranking" class="fs22" />
        <span class="title">品牌排名</span>
        <Tooltip :title="tooltipTitle" />
      </div>

      <!-- todoD -->
      <div class="info-div-hover">
        {{
          $parent.currentTab == "douyin"
            ? "抖音"
            : $parent.currentTab == "kuaishou"
            ? "快手"
            : $parent.currentTab == "dongchedi"
            ? "懂车帝"
            : ""
        }}平台数据
      </div>
      <!-- <div @click="routerTo" class="route-div">
        <span class="route">查看更多</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div> -->
    </div>
    <div class="info-div" v-if="rankingList">
      <WebpImage
        :src="rankingList.avatar ? ossHost + rankingList.avatar : ''"
        :width="'68px'"
        :height="'68px'"
        :radius="'16px'"
      />
      <div class="info-item">
        <div class="font1">粉丝总数</div>
        <div class="font2">{{ rankingList.fans_count | changeNum }}</div>
      </div>
      <div class="info-item">
        <div class="font1">直播观看</div>
        <div class="font2">
          {{ rankingList.live_total_watch_user_time | changeNum }}
        </div>
      </div>
      <div class="info-item">
        <div class="font1">营销价值</div>
        <div class="font2">¥{{ rankingList.marketing_value | changeNum }}</div>
      </div>
    </div>

    <a-table
      :columns="columns"
      v-if="rankingList"
      :data-source="rankingList.list"
      :rowKey="(data) => data.title"
      :pagination="false"
      class="table-style"
      :locale="{ emptyText: '暂无数据' }"
    >
      <div slot="customTitle" style="padding-left: 20px">
        <div>排名</div>
      </div>
      <div slot="ranking" slot-scope="scope" class="td-style td-left ranking">
        <span
          style="margin: 0 12px 0 20px"
          :class="
            scope.ranking == 1
              ? 'bc1'
              : scope.ranking == 2
              ? 'bc2'
              : scope.ranking == 3
              ? 'bc3'
              : 'bc'
          "
          >{{ scope.ranking }}</span
        >
        <svg-icon
          v-if="scope.ranking_over_last_month > 0 && scope.ranking < 1000"
          icon-class="new-up"
          style="font-size: 12px"
        />
        <svg-icon
          v-if="scope.ranking_over_last_month < 0 && scope.ranking < 1000"
          icon-class="new-down"
          style="font-size: 12px"
        />
        <span
          class="font-color23"
          v-if="scope.ranking_over_last_month && scope.ranking < 1000"
          >{{ Math.abs(scope.ranking_over_last_month) || "" }}</span
        >
      </div>
      <div slot="name" slot-scope="scope" class="td-style">
        <span
          :class="
            scope.ranking == 1
              ? 'table-name ranking-color1'
              : scope.ranking == 2
              ? 'table-name ranking-color2'
              : scope.ranking == 3
              ? 'table-name ranking-color3'
              : 'table-name'
          "
          >{{ scope.title }}</span
        >
      </div>
    </a-table>
  </div>
</template>

<script>
import * as vuex from "vuex";
import Tooltip from "@/components/Tooltip/Tooltip";
import WebpImage from "@/components/WebpImage/WebpImage";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardRanking",
  data() {
    return {
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      tooltipTitle:
        "粉丝总数：最新总数据；直播观看、营销价值：本月增量 ",
      columns: [
        {
          width: "130px",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "ranking" },
        },
        {
          title: "名称",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        // {
        //   title: "增降",
        //   width: "80px",
        //   align: "center",
        //   scopedSlots: { customRender: "increase" },
        // },
      ],
    };
  },
  computed: {
    ...mapState({
      rankingList: (state) => state.rankingList, // 品牌排名
      rankingLoading: (state) => state.rankingLoading,
    }),
  },
  components: {
    Tooltip,
    WebpImage,
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions(["getBrandRanking"]),
    ...mapMutations(["changeState"]),
    //   查看更多跳转
    initData() {
      this.getBrandRanking({
        platform: this.$parent.currentTab,
        principal_id: this.$parent.principal_id,
      });
    },
    routerTo() {
      this.$router.push({
        path: "/brand/generalList",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fs22 {
  font-size: 22px;
}
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 380px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .title {
      @include font_color("font_color20");
      font-size: 16px;
      margin-left: 10px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      .route {
        @include font_color("font_color28");
        font-size: 12px;
      }
      .arrow {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 10px;
        @include background_color("background_color5");
        @include font_color("font_color22");
        border-radius: 4px;
        transition: all 0.5s;
      }
    }
    .route-div:hover {
      .arrow {
        @include background_color("background_color1");
        @include font_color("font_color20");
      }
    }
  }
  .info-div {
    height: 68px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-hover {
      font-size: 12px;
      @include font_color("font_color22");
    }
    .info-item {
      width: 80px;
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .font1 {
        @include font_color("font_color23");
        font-size: 12px;
      }
      .font2 {
        @include font_color("font_color20");
        font-size: 18px;
      }
    }
  }
  .table-style {
    .ranking > span {
      width: 28px;
      height: 20px;
      border-radius: 14px;
      display: inline-block;
      text-align: center;
      line-height: 20px;
    }
    .bc1 {
      @include background_color("background_color15");
      @include font_color("font_color25");
    }
    .bc2 {
      @include background_color("background_color18");
      @include font_color("font_color25");
    }
    .bc3 {
      @include background_color("background_color49");
      @include font_color("font_color25");
    }
    .bc {
      @include background_color("background_color51");
      @include font_color("font_color22");
    }
    .table-name {
      font-size: 14px;
      font-weight: bold;
      @include font_color("font_color21");
    }
    .ranking-color1 {
      @include font_color("font_color26");
    }
    .ranking-color2 {
      @include font_color("font_color29");
    }
    .ranking-color3 {
      @include font_color("font_color32");
    }
  }
  .td-style {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .td-left {
    text-align: left;
  }
  .td-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .td-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color23 {
    @include font_color("font_color23");
  }
}
</style>
