import Nr from '@/utils/NetworldRequest'
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()
function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}

export default {
  getBcAwemeList(body) {
    return platformRequest(body.platform).get('/jjt/new/brand/benz/aweme-hot', { params: body.params })
  },
  getBcLiveList(body) {
    return platformRequest(body.platform).get('/jjt/new/brand/benz/live-hot', { params: body.params })
  },

}