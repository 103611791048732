import Nr from '@/utils/NetworldRequest'

const request = (new Nr).getAxios()

function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

export default {
  //
  getLiveCalendar(params) {
    const { platform, ...otherParams } = params

    return request({
      url: '/jjt/benz/show/calendar',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: otherParams
    }) 
  },
  //
  getLiveRankTop3(params) {
    const { platform, ...otherParams } = params

    return request({
      url: '/jjt/benz/show/calendarTop3',
      method: 'get',
      baseURL: getBaseUrl(platform),
      params: otherParams
    })
  }
}
