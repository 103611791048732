<template>
  <div class="component-wrap">
    <a-spin :spinning="anchorLoading" class="spin-class" />
    <div class="title-div">
      <div>
        <svg-icon icon-class="anchor" class="fs22" />
        <span class="title">品牌最强主播榜</span>
        <Tooltip :title="tooltipTitle" />
      </div>
      <!-- <div @click="routerTo" class="route-div">
        <span class="route">查看更多</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div> -->
    </div>
    <div class="main-container" v-if="bestAuthorList">
      <div
        class="main-item"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="main-item-title" v-if="bestAuthorList[item.value]">
          {{ item.title }}
        </div>
        <div class="main-item-info" v-if="bestAuthorList[item.value]">
          <div>
            <WebpImage 
              :src="bestAuthorList[item.value].avatar" 
              :width="'30px'" 
              :height="'30px'" 
              :radius="'50%'" 
              style="margin: 0 10px 0 20px;"
            />
            <span>{{ bestAuthorList[item.value].nickname }}</span>
          </div>
          <span v-if="item.value == 'max_live_time_author'">{{
            (bestAuthorList[item.value].value || 0) | disposeLongTime_h
          }}</span>
          <span v-else>{{ bestAuthorList[item.value].value | changeNum }}</span>
        </div>
      </div>
    <Empty style="margin-top: 100px;" :title="'暂无数据'" v-if="!bestAuthorList || (!bestAuthorList['max_fans_author'] && !bestAuthorList['max_month_fans_author'] && !bestAuthorList['max_live_count_author'] && !bestAuthorList['max_live_time_author'])"/>
    </div>

  </div>
</template>

<script>
import * as vuex from "vuex";
import Tooltip from "@/components/Tooltip/Tooltip"
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty"
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardRanking",
  data() {
    return {
      tooltipTitle: '本月数据',
      list: [
        {
          title: "粉丝最多",
          value: "max_fans_author",
        },
        {
          title: "粉丝月度增量",
          value: "max_month_fans_author",
        },
        {
          title: "本月开播场次最多",
          value: "max_live_count_author",
        },
        {
          title: "本月累计直播时长",
          value: "max_live_time_author",
        },
      ],
    };
  },
  components: {
    Tooltip,
    WebpImage,
    Empty
  },
  computed: {
    ...mapState({
      bestAuthorList: (state) => state.bestAuthorList, // 最强主播榜
      anchorLoading: (state) => state.anchorLoading,
    }),
  },
  filters: {
      formatDuration(number) {
          if (!number || number === 0) {
              return "0时0分";
          } else {
              let h = (number / (60 * 60)) | 0;
              let m = ((number % (60 * 60)) / 60) | 0;
              return `${h}时${m}分`;
          }
      }
  },
  created() {
    if (this.$parent.principal_id == "") {
      this.initData();
    }
  },
  methods: {
    ...mapActions(["getBestAuthor"]),
    ...mapMutations(["changeState"]),
    initData() {
      this.getBestAuthor({
        platform: this.$parent.currentTab,
      });
    },
    //   查看更多跳转
    routerTo() {},
    routerToPro(item) {
      this.$router.push({
        path: "/dealer/production",
        query: {
          type: this.$parent.currentTab,
          author_id: this.bestAuthorList[item.value].author_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fs22 {
  font-size: 22px;
}
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 260px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .title {
      @include font_color("font_color20");
      font-size: 16px;
      margin-left: 10px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      .route {
        @include font_color("font_color28");
        font-size: 12px;
      }
      .arrow {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 10px;
        @include background_color("background_color5");
        transition: all 0.5s;
      }
    }
    .route-div:hover {
      .arrow {
        @include background_color("background_color1");
      }
    }
  }
  .main-container {
    width: 100%;
    .main-item {
      &-title {
        width: 100%;
        height: 30px;
        @include background_color("background_color5");
        font-size: 12px;
        @include font_color("font_color22");
        line-height: 30px;
        padding-left: 23px;
        cursor: pointer;
      }
      &-info {
        width: 100%;
        height: 68px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        >div {
          display: flex;
          align-items: center;
        }
        span {
          @include font_color("font_color21");
          font-size: 14px;
        }
        > span {
          @include font_color("font_color22");
          font-size: 12px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
