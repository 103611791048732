<template>
  <PanleWrap>
    <div class="list_header">
      <platform-header
        title="品牌官号作品榜"
        :tabData="tabData"
        @tabChange="handlePlatformClick"
      />
      <div class="tools">
        <div class="btn-box">
          <ButtonGroup
            :tab-data="tabList"
            @tabChange="handleTypeClick"
            :showTag="true"
          />
          <Order
            :order-list="orderList"
            :order-index="orderIndex"
            :titleText="'月度排名:'"
            @order-click="hadnleOrderClick"
            style="margin-left: 30px"
          />
        </div>
        <div class="btn-box">
          <a-month-picker
            v-model="searchTime"
            placeholder="Select month"
            class="aft-month-picker default"
            :disabled-date="disabledDate"
            @change="onChange"
            locale="zh-cn"
          />
        </div>
      </div>
    </div>
    <a-spin :spinning="mixin_isSpinning">
      <div class="list-content" v-show="total && total > 0">
        <template v-if="dataList && dataList.length > 0 && isLive">
          <div
            class="list-content-item"
            v-for="(item, index) in dataList"
            :key="item.principal_id"
            :ref="`item_${item.principal_id}`"
          >
            <div class="cover-container">
              <div class="img-box">
                <WebpImage
                  :src="item.cover"
                  :scale="'1-1'"
                  :width="'100%'"
                  :height="'100%'"
                  :radius="'0px'"
                />
              </div>
              <div class="shadow-container">
                <div class="title">
                  {{ item.title || "-" }}
                </div>
                <div class="info">
                  <div class="avatar-box">
                    <WebpImage
                      :src="item.avatar"
                      :scale="'1-1'"
                      :width="'100%'"
                      :height="'100%'"
                      :radius="'0px'"
                    />
                  </div>
                  <div class="name">{{ item.nickname || "-" }}</div>
                  <!-- <div
                    :class="
                      item.ranking === 1
                        ? 'ranking-tag first'
                        : item.ranking === 2
                        ? 'ranking-tag second'
                        : item.ranking === 3
                        ? 'ranking-tag third'
                        : 'ranking-tag'
                    "
                  >
                    {{ item.ranking }}
                  </div> -->
                </div>
                <div class="detail">
                  <div class="detail-item">
                    <span>互动量</span>
                    <span style="color: #ffca5c">{{
                      item.live_interactive_count | changeNum
                    }}</span>
                  </div>
                  <div class="detail-item">
                    <span>开始时间</span>
                    <span>{{ item.publish_time.slice(0, 10).split('-').join('/') }}</span>
                  </div>
                  <div class="detail-item">
                    <span>直播观看</span>
                    <span>{{
                      item.live_total_watch_user_time | changeNum
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="dataList && dataList.length > 0 && isVideo">
          <div
            class="list-content-item"
            v-for="(item, index) in dataList"
            :key="item.principal_id"
            :ref="`item_${item.principal_id}`"
          >
            <div class="cover-container">
              <div class="img-box" style="width: 100%; height: 100%; left: 0">
                <WebpImage
                  :src="item.cover"
                  :scale="'1-1'"
                  :width="'100%'"
                  :height="'100%'"
                  :radius="'0px'"
                />
              </div>
              <div class="shadow-container">
                <div class="title">
                  {{ item.title || "-" }}
                </div>
                <div class="info">
                  <div class="avatar-box">
                    <WebpImage
                      :src="item.avatar"
                      :scale="'1-1'"
                      :width="'100%'"
                      :height="'100%'"
                      :radius="'0px'"
                    />
                  </div>
                  <div class="name">{{ item.nickname || "-" }}</div>
                  <div
                    :class="
                      item.ranking === 1
                        ? 'ranking-tag first'
                        : item.ranking === 2
                        ? 'ranking-tag second'
                        : item.ranking === 3
                        ? 'ranking-tag third'
                        : 'ranking-tag'
                    "
                  >
                    {{ item.ranking }}
                  </div>
                  <!-- <div class="ranking-wrap">
                    <div
                      :class="
                        item.ranking === 1
                          ? 'num first'
                          : item.ranking === 2
                          ? 'num second'
                          : item.ranking === 3
                          ? 'num third'
                          : item.principal_id === my_ranking.principal_id
                          ? 'num other'
                          : 'num'
                      "
                    ></div>
                  </div> -->
                </div>
                <div class="detail">
                  <div class="detail-item">
                    <span>互动量</span>
                    <span style="color: #ffca5c">{{
                      item.aweme_relation_count | changeNum
                    }}</span>
                  </div>
                  <div class="detail-item">
                    <span>发布时间</span>
                    <span>{{ item.publish_time.slice(0, 10).split('-').join('/') }}</span>
                  </div>
                  <div class="detail-item">
                    <span>播放量</span>
                    <span>{{
                      item.aweme_play_volume_total_di | changeNum
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <template v-if="!total || total == 0">
        <div class="empty-container">
          <Empty />
        </div>
      </template>
    </a-spin>

    <div class="page-container">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
  </PanleWrap>
</template>

<script>
import PanleWrap from "./layout/PanelWrap";
import api from "@/api/worklist.js";
import _ from "lodash";
import moment from "moment";
import mixin from "./mixins/mixin";
import Order from "./components/Order";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";

const OSS_HOST = process.env.VUE_APP_LIVE_OSS_HOST;

//直播条件筛选
const liveOrderList = [
  {
    text: "直播互动",
    value: "1",
  },
  {
    text: "直播观看",
    value: "2",
  },
];

//视频条件筛选
const videoOrderList = [
  {
    text: "视频互动",
    value: "1",
  },
  {
    text: "视频播放",
    value: "5",
  },
];

export default {
  name: "worksList",
  components: {
    PanleWrap,
    Order,
    PlatformHeader,
    ButtonGroup,
    WebpImage,
    Empty,
  },
  mixins: [mixin],
  filters: {
    formatDuration(number) {
      if (!number || number === 0) {
        return "0时0分";
      } else {
        let h = (number / (60 * 60)) | 0;
        let m = ((number % (60 * 60)) / 60) | 0;
        return `${h}时${m}分`;
      }
    },
    formatPublishTime(str) {
      if (!str) {
        return "暂无时间";
      } else {
        return moment(str).format("YYYY/MM/DD");
      }
    },
  },
  data() {
    return {
      searchTime: moment().subtract(0, "months"),
      disabledDate(current) {
        return current >= moment().startOf("month").subtract("month", -1);
      },
      startTime: "",
      endTime: "",
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
      ],
      tabList: [
        {
          label: "视频",
          value: "2",
          tag: "VIDEO",
        },
        {
          label: "直播",
          value: "1",
          tag: "LIVE",
        },
      ],
      platform: "douyin", // 平台(抖音:douyin,快手:kuaishou)
      type: "2",
      liveSort: "1",
      videoSort: "1",
      orderIndex: 0,
      liveIndex: 0,
      videoIndex: 0,
      orderList: videoOrderList,
      dataList: [],
      OSS_HOST,
      pagination: {
        size: 8,
        current: 1,
        total: 0,
      },
      isLive: false,
      isVideo: true,
      current: 1,
      pageSize: 12,
      total: 0,
      pageRouterTimer: null,
    };
  },
  async created() {
    this.setPageTimer();
    let timeObj = this.calcStartEndTime(moment().subtract(0, "months"));
    this.startTime = timeObj.startTime;
    this.endTime = timeObj.endTime;
    this.getList = this.requestDecorator(() => {
      const body = {
        platform: this.platform,
        params: {
          start_time: this.startTime,
          end_time: this.endTime,
          current: this.current,
          size: this.pageSize,
          sort: this.type === "1" ? this.liveSort : this.videoSort,
        },
      };

      if (this.type === "1") {
        return api.getBcLiveList(body).then((res) => {
          this.dataList = _.get(res, "data.data.list", []);
          this.total = _.get(res, "data.data.total", 0);
          return res;
        });
      } else {
        return api.getBcAwemeList(body).then((res) => {
          this.dataList = _.get(res, "data.data.list", []);
          this.total = _.get(res, "data.data.total", 0);
          return res;
        });
      }
    });

    await this.getList();
  },
  methods: {
    getList() {},
    pageChange(val) {
      this.setPageTimer();
      this.current = val;
      this.getList();
    },
    calcStartEndTime(date) {
      let start = date.startOf("month").format("YYYY-MM-DD HH:mm:ss");
      let end = date.endOf("month").format("YYYY-MM-DD HH:mm:ss");
      return {
        startTime: start,
        endTime: end,
      };
    },
    hadnleOrderClick(res) {
      this.setPageTimer();

      console.log(res);
      this.current = 1;

      if (this.type === "1") {
        this.liveSort = res.data.value;
        this.liveIndex = res.index;
      } else {
        this.videoSort = res.data.value;
        this.videoIndex = res.index;
      }
      this.orderIndex = res.index;

      this.getList();
    },
    handlePlatformClick(val) {
      this.setPageTimer();

      this.current = 1;
      this.platform = val;
      this.getList();
    },
    handleTypeClick(val) {
      this.setPageTimer();

      this.type = val;
      this.current = 1;
      if (val === "1") {
        this.orderList = liveOrderList;
        this.orderIndex = this.liveIndex;
        this.isLive = true;
        this.isVideo = false;
      } else {
        this.orderList = videoOrderList;
        this.orderIndex = this.videoIndex;
        this.isLive = false;
        this.isVideo = true;
      }
      this.getList();
    },
    onChange(date, dateString) {
      if (!date) {
        return;
      }
      this.setPageTimer();

      this.current = 1;
      let obj = this.calcStartEndTime(date);
      this.startTime = obj.startTime;
      this.endTime = obj.endTime;
      this.getList();
    },
    // 重置定时器
    setPageTimer() {
      clearTimeout(this.pageRouterTimer);
      this.pageRouterTimer = null;
      this.pageRouterTimer = setTimeout(() => {
        this.pageRouter();
      }, 1000 * 20);
    },
    // 定时路由跳转
    pageRouter() {
      clearTimeout(this.pageRouterTimer);
      this.pageRouterTimer = null;
      this.$router.push(`/bcCoreScreen/liveArea`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

img {
  max-width: 100%;
}

.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.list_header {
  margin-bottom: 20px;
  > h3 {
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    @include font_color("font_color2");
    margin: 0;
  }
}
.tools {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;
  padding: 0 60px;
}

.btn-box {
  display: flex;
  align-items: center;
}

.my-ranking {
  padding: 0 30px 0 16px;
  margin-right: 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  cursor: pointer;
  @include background_color("background_color51");
  span {
    padding-left: 6px;
    @include font_color("font_color4");
  }
}
.page-container {
  text-align: center;
  margin-top: 22px;
}
.empty-container {
  width: 100%;
  height: 780px;
  padding-top: 300px;
}

.list-content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 780px;
  overflow-y: hidden;
  padding: 0 60px;
  &-item {
    width: 285px;
    height: 381px;
    border-radius: 15px 15px 17px 17px;
    margin-bottom: 18px;
    margin-left: 15px;
    &:nth-child(6n + 1) {
      margin-left: 0px;
    }
    overflow: hidden;
    .cover-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      > .img-box {
        position: absolute;
        left: -48px;
        top: 0;
        width: 381px;
        height: 381px;
        display: inline-block;
        z-index: 100;
      }
      .shadow-container {
        width: 285px;
        height: 160px;
        padding: 35px 16px 16px;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.6) 100%
        );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 200;
        .title {
          display: inline-block;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          width: 100%;
          height: 18px;
          margin-bottom: 12px;
        }
        .info {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;
          > .avatar-box {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
          }
          .name {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
          }
          .ranking-tag {
            width: 28px;
            height: 20px;
            border-radius: 12px;
            font-size: 12px;
            line-height: 20px;
            margin-left: 14px;
            @include background_color("background_color66");
            @include font_color("font_color35");
          }

          .ranking-tag.first {
            @include background_color("background_color34");
            @include font_color("font_color25");
          }
          .ranking-tag.second {
            @include background_color("background_color18");
            @include font_color("font_color25");
          }
          .ranking-tag.third {
            @include background_color("background_color49");
            @include font_color("font_color25");
          }
        }
        .detail {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          height: 39px;
          &-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 33.2%;
            height: 100%;
            > span:first-child {
              font-size: 12px;
              color: rgba(255, 255, 255, 0.6);
            }
            > span:last-child {
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>