<template>
  <div class="container">
    <platform-header
      title="矩阵分析"
      :tabData="platList"
      @tabChange="handlePlatChange"
    />

    <section class="section">
      <header class="section-header">
        <div class="tool-bar">
          <div class="tool-bar__left"></div>
          <div class="tool-bar__right">
            <a-month-picker
              class="aft-month-picker default"
              v-model="month"
              locale="zh-cn"
              placeholder="Select month"
              valueFormat="YYYY-MM"
              @change="handleMonthChange"
            />
          </div>
        </div>
      </header>
      <main class="section-main">
        <div class="data-card-container">
          <div class="data-card-container__left">
            <div class="data-card data-card--tower">
              <div class="brand">
                <div class="brand__avatar">
                  <img :src="ossHost + principalInfo.principal_image" alt="">
                </div>
                <div class="brand__name">
                  {{ principalInfo.principal_name }}
                </div>
              </div>
              <div class="tower">
                <div class="floor-item floor-item--1">
                  <div class="floor-item__value">
                    <count-to
                      :start-val="startVal"
                      :end-val="principalInfo.official_author_count"
                      :decimals='decimals'
                      :separator="separator"
                    />
                  </div>
                  <div class="floor-item__label">官号</div>
                </div>
                <div class="floor-item floor-item--2">
                  <div class="floor-item__value">
                    <count-to
                      :start-val="startVal"
                      :end-val="principalInfo.dealer_author_count"
                      :decimals='decimals'
                      :separator="separator"
                    />
                  </div>
                  <div class="floor-item__label">经销商账号</div>
                </div>
                <div class="floor-item floor-item--3">
                  <div class="floor-item__value">
                    <count-to
                      :start-val="startVal"
                      :end-val="principalInfo.kol_author_count"
                      :decimals='decimals'
                      :separator="separator"
                    />
                  </div>
                  <div class="floor-item__label">KOL/KOC</div>
                </div>
              </div>
              <a-spin
                class="spin-class"
                v-if="dataLoading"
                :spinning="dataLoading"
              />
            </div>
          </div>
          <div class="data-card-container__right">
            <div class="data-card-container__top">
              <div class="data-card data-card--1">
                <div class="data">
                  <div class="data__label">账号总数</div>
                  <div class="data__value">
                    <span class="data__num">
                      <count-to
                        :start-val="startVal"
                        :end-val="getNumber(accountCount)"
                        :decimals='decimals'
                        :separator="separator"
                      />
                    </span>
                    <span
                      class="data__suffix"
                      :class="{'data__suffix--zh': generateNumSuffix(accountCount) === '亿'}"
                    >
                      {{ generateNumSuffix(accountCount) }}
                    </span>
                  </div>
                </div>
                <a-spin
                  class="spin-class"
                  v-if="dataLoading"
                  :spinning="dataLoading"
                />
              </div>
              <div class="data-card data-card--2">
                <div class="data">
                  <div class="data__label">月活主播</div>
                  <div class="data__value">
                    <span class="data__num">
                      <count-to
                        :start-val="startVal"
                        :end-val="getNumber(principalInfo.active_author_count)"
                        :decimals='decimals'
                        :separator="separator"
                      />
                    </span>
                    <span
                      class="data__suffix"
                      :class="{'data__suffix--zh': generateNumSuffix(principalInfo.active_author_count) === '亿'}"
                    >
                      {{ generateNumSuffix(principalInfo.active_author_count) }}
                    </span>
                  </div>
                </div>
                <a-spin
                  class="spin-class"
                  v-if="dataLoading"
                  :spinning="dataLoading"
                />
              </div>
              <div class="data-card data-card--3">
                <div class="data">
                  <div class="data__label">入驻比例</div>
                  <div class="data__value">
                    <span class="data__num">
                      <count-to
                        :start-val="startVal"
                        :end-val="Number(numRateNew(principalInfo.active_rate))"
                        :decimals='decimals'
                        :separator="separator"
                      />
                    </span>
                    <span class="data__suffix data__suffix--rate">
                      {{ principalInfo.active_rate !== null ? '%' : '' }}
                    </span>
                  </div>
                </div>
                <a-spin
                  class="spin-class"
                  v-if="dataLoading"
                  :spinning="dataLoading"
                />
              </div>
            </div>
            <div class="data-card-container__bottom">
              <div class="data-card data-card--4">
                <div class="data">
                  <div class="data__label">粉丝总量</div>
                  <div class="data__value">
                    <span class="data__num">
                      <count-to
                        :start-val="startVal"
                        :end-val="getNumber(principalInfo.fans_count)"
                        :decimals="generateNumSuffix(principalInfo.fans_count) ? 1 : 0"
                        :separator="separator"
                      />
                    </span>
                    <span
                      class="data__suffix"
                      :class="{'data__suffix--zh': generateNumSuffix(principalInfo.fans_count) === '亿'}"
                    >
                      {{ generateNumSuffix(principalInfo.fans_count) }}
                    </span>
                  </div>
                </div>
                <a-spin
                  class="spin-class"
                  v-if="dataLoading"
                  :spinning="dataLoading"
                />
              </div>
              <div class="data-card data-card--4">
                <div class="data">
                  <div class="data__label">直播场次</div>
                  <div class="data__value">
                    <span class="data__num">
                      <count-to
                        :start-val="startVal"
                        :end-val="getNumber(principalInfo.live_count)"
                        :decimals="generateNumSuffix(principalInfo.live_count) ? 1 : 0"
                        :separator="separator"
                      />
                    </span>
                    <span
                      class="data__suffix"
                      :class="{'data__suffix--zh': generateNumSuffix(principalInfo.live_count) === '亿'}"
                    >
                      {{ generateNumSuffix(principalInfo.live_count) }}
                    </span>
                  </div>
                </div>
                <a-spin
                  class="spin-class"
                  v-if="dataLoading"
                  :spinning="dataLoading"
                />
              </div>
              <div class="data-card data-card--4">
                <div class="data">
                  <div class="data__label">视频发布</div>
                  <div class="data__value">
                    <span class="data__num">
                      <count-to
                        :start-val="startVal"
                        :end-val="getNumber(principalInfo.aweme_count)"
                        :decimals="generateNumSuffix(principalInfo.aweme_count) ? 1 : 0"
                        :separator="separator"
                      />
                    </span>
                    <span
                      class="data__suffix"
                      :class="{'data__suffix--zh': generateNumSuffix(principalInfo.aweme_count) === '亿'}"
                    >
                      {{ generateNumSuffix(principalInfo.aweme_count) }}
                    </span>
                  </div>
                </div>
                <a-spin
                  class="spin-class"
                  v-if="dataLoading"
                  :spinning="dataLoading"
                />
              </div>
              <div class="data-card data-card--4">
                <div class="data">
                  <div class="data__label">互动总量</div>
                  <div class="data__value">
                    <span class="data__num">
                      <count-to
                        :start-val="startVal"
                        :end-val="getNumber(principalInfo.interaction_total)"
                        :decimals="generateNumSuffix(principalInfo.interaction_total) ? 1 : 0"
                        :separator="separator"
                      />
                    </span>
                    <span
                      class="data__suffix"
                      :class="{'data__suffix--zh': generateNumSuffix(principalInfo.interaction_total) === '亿'}"
                    >
                      {{ generateNumSuffix(principalInfo.interaction_total) }}
                    </span>
                  </div>
                </div>
                <a-spin
                  class="spin-class"
                  v-if="dataLoading"
                  :spinning="dataLoading"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>

    <section class="section">
      <header class="section-header">
        <div class="tool-bar">
          <div class="tool-bar__left">
            <ButtonGroup
              :tab-data="btnList"
              @tabChange="handleBtnChange"
            />
          </div>
          <div class="tool-bar__right"></div>
        </div>
      </header>
      <main class="section-main">
        <div class="chart-card-container">
          <div class="chart-card">
            <my-chart
              v-if="day.length > 0"
              id="line1"
              :options="options1"
            />
            <div class="empty-wrap" v-else>
              <empty />
            </div>
            <a-spin
              class="spin-class"
              v-if="chartLoading"
              :spinning="chartLoading"
            />
          </div>
          <div class="chart-card">
            <my-chart
              v-if="day.length > 0"
              id="line2"
              :options="options2"
            />
            <div class="empty-wrap" v-else>
              <empty />
            </div>  
            <a-spin
              class="spin-class"
              v-if="chartLoading"
              :spinning="chartLoading"
            />
          </div>
          <div class="chart-card">
            <my-chart
              v-if="day.length > 0"
              id="line3"
              :options="options3"
            />
            <div class="empty-wrap" v-else>
              <empty />
            </div>
            <a-spin
              class="spin-class"
              v-if="chartLoading"
              :spinning="chartLoading"
            />
          </div>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import CountTo from 'vue-count-to'
import PlatformHeader from "@/components/PlatformTab/PlatformHeader"
import ButtonGroup from "@/components/PlatformTab/ButtonGroup"
import MyChart from "@/components/MyChart/MyChart"
import Empty from '@/components/Empty/Empty'
import { changeNumNew, generateNumSuffix, numRateNew } from '@/filters'
import deepClone from '@/utils/clone-utils'
import { platList, defaultPrincipalInfo, btnList, options1, options2, options3 } from './constant'
import api from "@/api/dealerOfOneself"

const ossHost = process.env.VUE_APP_LIVE_OSS_HOST

export default {
  name: "dealerOfOneself",
  components: {
    CountTo,
    PlatformHeader,
    ButtonGroup,
    MyChart,
    Empty
  },
  data() {
    return {
      platList,
      currPlat: "douyin",
      month: dayjs().subtract(1, 'month').format('YYYY-MM'),
      dataLoading: false,
      principalInfo: {...defaultPrincipalInfo},
      ossHost,
      startVal: 0,
      decimals: 0,
      separator: '',
      btnList,
      currType: 'increase',
      chartLoading: false,
      day: [],
      options1,
      options2,
      options3,
      pageRouterTimer: null,
    }
  },
  created() {
    this.setPageTimer()
    this.getPrincipalInfo()
    this.getLiveAweme()
  },
  computed: {
    accountCount () {
      const {
        official_author_count = 0,
        dealer_author_count = 0,
        kol_author_count = 0
      } = this.principalInfo

      return official_author_count + dealer_author_count + kol_author_count
    }
  },
  methods: {
    changeNumNew,
    generateNumSuffix,
    numRateNew,
    //
    async getPrincipalInfo () {
      this.dataLoading = true
      try {
        const daysInMonth = dayjs(this.month).daysInMonth()
        const params = {
          type: this.currPlat,
          // 以下是接口需要的参数
          start_time: `${this.month}-01 00:00:00`,
          end_time: `${this.month}-${daysInMonth} 23:59:59`
        }
        const res = await api.getPrincipalInfo(params)
        const { code, data } = res.data

        if (code === 0) {
          this.principalInfo = data
          this.dataLoading = false
        } else {
          this.principalInfo = { ...defaultPrincipalInfo }
          this.dataLoading = false
        }
      } catch (e) {
        console.log(e)
        this.principalInfo = { ...defaultPrincipalInfo }
        this.dataLoading = false
      } 
    },
    //
    async getLiveAweme () {
      this.chartLoading = true
      try {
        const daysInMonth = dayjs(this.month).daysInMonth()
        const params = {
          type: this.currPlat,
          // 以下是接口需要的参数
          start_time: `${this.month}-01 00:00:00`,
          end_time: `${this.month}-${daysInMonth} 23:59:59`
        }
        const res = await api.getLiveAweme(params)
        const { code, data } = res.data

        if (code === 0) {
          this.day = data.day || []
          this.chartData = data
          this.setOptions1()
          this.setOptions2()
          this.setOptions3()
          this.chartLoading = false
        } else {
          this.day = []
          this.chartLoading = false
        }
      } catch (e) {
        console.log(e)
        this.day = []
        this.chartLoading = false
      }
    },
    getNumber (val) {
      return Number(this.changeNumNew(val ? val : 0))
    },
    setOptions1 () {
      console.log(this.chartData)
      const {
        day,
        aweme_count_total,
        live_count_total,
        fans_count_total,
        aweme_count_increase,
        live_count_increase,
        fans_count_increase
      } = this.chartData
      this.options1.xAxis.data= day
      switch (this.currType) {
        case 'total':
          this.options1.series[0].data = aweme_count_total || []
          this.options1.series[1].data = live_count_total || []
          this.options1.series[2].data = fans_count_total || []
          break
        case 'increase':
          this.options1.series[0].data = aweme_count_increase || []
          this.options1.series[1].data = live_count_increase || []
          this.options1.series[2].data = fans_count_increase || []
          break
      }
    },
    setOptions2 () {
      const {
        day,
        aweme_polling_count_total,
        aweme_play_count_total,
        aweme_like_count_total,
        aweme_comment_count_total,
        aweme_share_count_total,
        aweme_polling_count_increase,
        aweme_play_count_increase,
        aweme_like_count_increase,
        aweme_comment_count_increase,
        aweme_share_count_increase
      } = this.chartData

      this.options2 = deepClone(options2)
      this.options2.xAxis.data = day

      if (this.currPlat === 'douyin') {
        switch (this.currType) {
          case 'total':
            this.options2.series[0].data = aweme_polling_count_total || []
            this.options2.series[1].data = aweme_play_count_total || []
            this.options2.series[2].data = aweme_like_count_total || []
            this.options2.series[3].data = aweme_comment_count_total || []
            this.options2.series[4].data = aweme_share_count_total || []
            break
          case 'increase':
            this.options2.series[0].data = aweme_polling_count_increase || []
            this.options2.series[1].data = aweme_play_count_increase || []
            this.options2.series[2].data = aweme_like_count_increase || []
            this.options2.series[3].data = aweme_comment_count_increase || []
            this.options2.series[4].data = aweme_share_count_increase || []
            break
        }
      } else {
        switch (this.currType) {
          case 'total':
            this.options2.legend.data.splice(2, 1)
            this.options2.series.splice(2, 1)
            this.options2.series[0].data = aweme_polling_count_total || []
            this.options2.series[1].data = aweme_play_count_total || []
            this.options2.series[2].data = aweme_comment_count_total || []
            this.options2.series[3].data = aweme_share_count_total || []
            break
          case 'increase':
            this.options2.series[0].data = aweme_polling_count_increase || []
            this.options2.series[1].data = aweme_play_count_increase || []
            this.options2.series[2].data = aweme_like_count_increase || []
            this.options2.series[3].data = aweme_comment_count_increase || []
            this.options2.series[4].data = aweme_share_count_increase || []
            break
        }
      }
    },
    setOptions3 () {
      const {
        day,
        live_polling_total,
        live_watch_count_total,
        live_like_count_total,
        live_comment_count_total,
        live_polling_increase,
        live_watch_count_increase,
        live_like_count_increase,
        live_comment_count_increase
      } = this.chartData

      this.options3 = deepClone(options3)
      this.options3.xAxis.data = day

      if (this.currPlat === 'douyin') {
        switch (this.currType) {
          case 'total':
            this.options3.series[0].data = live_polling_total || []
            this.options3.series[1].data = live_watch_count_total || []
            this.options3.series[2].data = live_like_count_total || []
            this.options3.series[3].data = live_comment_count_total || []
            break
          case 'increase':
            this.options3.series[0].data = live_polling_increase || []
            this.options3.series[1].data = live_watch_count_increase || []
            this.options3.series[2].data = live_like_count_increase || []
            this.options3.series[3].data = live_comment_count_increase || []
            break
        }
      } else {
        switch (this.currType) {
          case 'total':
            this.options3.legend.data.splice(1, 1)
            this.options3.series.splice(1, 1)
            this.options3.series[0].data = live_polling_total || []
            this.options3.series[1].data = live_like_count_total || []
            this.options3.series[2].data = live_comment_count_total || []
            break
          case 'increase':
            this.options3.legend.data[1].name = '观看人数'
            this.options3.series[1].name = '观看人数'
            this.options3.series[0].data = live_polling_increase || []
            this.options3.series[1].data = live_watch_count_increase || []
            this.options3.series[2].data = live_like_count_increase || []
            this.options3.series[3].data = live_comment_count_increase || []
            break
        }
      }
    },
    //
    handlePlatChange (val) {
      this.setPageTimer()
      this.currPlat = val
      this.getPrincipalInfo()
      this.getLiveAweme()
    },
    //
    handleMonthChange (val) {
      this.setPageTimer()
      this.getPrincipalInfo()
      this.getLiveAweme()
    },
    //
    handleBtnChange (val) {
      this.setPageTimer()
      this.currType = val
      this.setOptions1()
      this.setOptions2()
      this.setOptions3()
    },
    // 重置定时器
    setPageTimer() {
      clearTimeout(this.pageRouterTimer)
      this.pageRouterTimer = null;
      this.pageRouterTimer = setTimeout(() => {
        this.pageRouter();
      }, 1000 * 20);
    },
    // 定时路由跳转
    pageRouter() {
      clearTimeout(this.pageRouterTimer)
      this.pageRouterTimer = null;
      this.$router.push(`/bcPublicScreen/brandSummary1`);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

.container {
  padding: 0 0 50px;
}

.platform-container {
  padding-bottom: 14px;
}

.section {
  &-header {
    padding: 20px 60px;
    height: 80px;
  }

  &-main {
    padding: 0 60px;
  }

  + .section {
    margin: 10px 0 0;
  }
}

.tool-bar {
  display: flex;

  &__left {
    flex: 1;
    text-align: left;
  }

  &__right {
    flex: 1;
    text-align: right;
  }
}

.data-card-container {
  display: flex;
  height: 388px;

  &__left {
    width: 363px;
  }

  &__right {
    margin: 0 0 0 16px;
  }

  &__top {
    display: flex;
    margin: 0 0 0 -16px;
  }

  &__bottom {
    display: flex;
    margin: 16px 0 0 -16px;
  }
}

.brand {
  display: flex;
  align-items: center;

  &__avatar {
    width: 62px;
    height: 62px;
    background: #FFF;
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__name {
    flex: 1;
    margin: 0 0 0 20px;
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
  }
}

.tower {
  margin: 25px 0 0;
  padding: 44px 0 0;
  height: 264px;
  background: url("./images/img_tower.png") center no-repeat;
  background-size: 241px 264px;

  .floor-item {
    &__label {
      margin: 1px 0 0;
      color: #684B04;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }

    &__value {
      color: #684B04;
      font-size: 20px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 23px;
      text-align: center;
    }

    &--1 {
      .floor-item__label, .floor-item__value {
        color: #684B04;
      }
    }

    &--2 {
      margin: 38px 0 0;

      .floor-item__label, .floor-item__value {
        color: #1E4C9A;
      }
    }

    &--3 {
      margin: 31px 0 0;

      .floor-item__label, .floor-item__value {
        color: #5322A0;
      }
    }
  }
}

.data {
  position: relative;
  z-index: 1;

  &__label {
    color: #9CA8BA;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }

  &__value {
    margin: 43px 0 0;
    font-size: 0;
  }

  &__num {
    color: #FFF;
    font-size: 46px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 54px;
  }

  &__suffix {
    display: inline-block;
    margin: 0 0 0 -10px;
    color: #FFF;
    font-size: 46px;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 54px;

    &--rate {
      font-size: 36px;
    }

    &--zh {
      font-size: 30px;
    }
  }
}

.data-card {
  position: relative;
  background: #2B2F3B;
  border-radius: 16px;
  overflow: hidden;

  &--tower {
    padding: 20px 20px 17px;
    width: 363px;
    height: 388px;
  }

  &--1, &--2, &--3 {
    margin: 0 0 0 16px;
    padding: 30px 28px;
    width: 461px;
    height: 186px;

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 14px;
      right: 0;
      width: 213px;
      height: 172px;
    }
  }

  &--1 {
    background: linear-gradient(90deg, #FFDA7D 0%, #FFC840 100%);

    &:before {
      background: url("./images/img_bg_01.png") center no-repeat;
      background-size: cover;
    }

    .data {
      &__label {
        color: #684B04;
      }

      &__num, &__suffix {
        color: #684B04;
      }
    }
  }

  &--2 {
    background: linear-gradient(90deg, #A3C4FF 0%, #81AFFF 100%);
    &:before {
      background: url("./images/img_bg_02.png") center no-repeat;
      background-size: cover;
    }

    .data {
      &__label {
        color: #1E4C9A;
      }

      &__num, &__suffix {
        color: #1E4C9A;
      }
    }
  }

  &--3 {
    background: linear-gradient(90deg, #CBAAFF 0%, #B687FF 100%);
    &:before {
      background: url("./images/img_bg_03.png") center no-repeat;
      background-size: cover;
    }

    .data {
      &__label {
        color: #5322A0;
      }

      &__num, &__suffix {
        color: #5322A0;
      }
    }
  }

  &--4 {
    margin: 0 0 0 16px;
    padding: 30px 28px;
    width: 342px;
    height: 186px;
  }
}

.chart-card-container {
  display: flex;
  margin: 0 0 0 -16px;
}

.chart-card {
  position: relative;
  margin: 0 0 0 16px;
  padding: 30px 23px 28px;
  width: 589px;
  height: 358px;
  background: #2B2F3B;
  border-radius: 16px;
}

.empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.spin-class {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(54, 57, 66, 0.5);
}
</style>
<style lang="scss">
.tooltip {
  background: #3C4252;
  border-radius: 8px;

  &__head {
    padding: 6px 20px;
    color: #FFF;
    font-size: 10px;
    line-height: 14px;
    border-bottom: 1px solid rgba(#FFF, .1);
  }

  &__body {
    padding: 3px 20px 10px;
  }
}

.tooltip-item {
  display: flex;
  align-items: center;
  margin: 6px 0 0;
  height: 17px;

  .icon {
    margin: 0 6px 0 0;
    width: 22px;
    height: 10px;
  }

  .label {
    color: #9CA8BA;
    font-size: 12px;
    font-weight: 400;
  }
  
  .value {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
