import { render, staticRenderFns } from "./PanelWrap.vue?vue&type=template&id=7b85fbff&scoped=true&"
var script = {}
import style0 from "./PanelWrap.vue?vue&type=style&index=0&id=7b85fbff&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b85fbff",
  null
  
)

export default component.exports