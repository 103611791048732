<template>
  <div class="component-wrap">
    <a-spin :spinning="officialLoading" class="spin-class" />
    <div class="title-div">
      <div>
        <svg-icon icon-class="brand-official" class="fs22" />
        <span class="title">品牌官号</span>
      </div>
      <!-- <div @click="routerTo" class="route-div">
        <span class="route">查看更多</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div> -->
    </div>
    <div class="info-div" v-if="officialList && officialList.author">
      <WebpImage
        :src="officialList.author.avatar"
        :width="'68px'"
        :height="'68px'"
        :radius="'16px'"
      />
      <div class="info-item">
        <div class="font2">{{ officialList.author.nickname }}</div>
        <div class="font1">
          {{
            $parent.currentTab == "douyin"
              ? "抖音"
              : $parent.currentTab == "kuaishou"
              ? "快手"
              : $parent.currentTab == "dongchedi"
              ? "懂车帝"
              : "平台"
          }}号: {{ officialList.author.code }}
        </div>
      </div>
    </div>

    <a-table
      v-if="officialList && officialList.author"
      :columns="columns"
      :data-source="officialList.list"
      :rowKey="(data) => data.title"
      :pagination="false"
      class="table-style"
      :locale="{ emptyText: '暂无数据' }"
    >
      <div slot="customTitle" style="display: flex">
        <div>数据</div>
        <Tooltip :title="tooltipTitle" />
      </div>
      <div slot="customTitleRanking" style="padding-left: 20px">
        <div>排名</div>
      </div>
      <div slot="ranking" slot-scope="scope" class="td-style td-left ranking">
        <span
          style="margin: 0 12px 0 20px"
          :class="
            scope.ranking == 1
              ? 'bc1'
              : scope.ranking == 2
              ? 'bc2'
              : scope.ranking == 3
              ? 'bc3'
              : 'bc'
          "
          >{{ scope.ranking }}</span
        >
        <svg-icon
          v-if="scope.ranking_over_last_month > 0 && scope.ranking < 1000"
          icon-class="new-up"
          style="font-size: 12px"
        />
        <svg-icon
          v-if="scope.ranking_over_last_month < 0 && scope.ranking < 1000"
          icon-class="new-down"
          style="font-size: 12px"
        />
        <span class="font-color23" v-if="scope.ranking < 1000">{{
          Math.abs(scope.ranking_over_last_month) || ""
        }}</span>
      </div>
      <div slot="name" slot-scope="scope" class="td-style">
        <span
          :class="
            scope.ranking == 1
              ? 'table-name ranking-color1'
              : scope.ranking == 2
              ? 'table-name ranking-color2'
              : scope.ranking == 3
              ? 'table-name ranking-color3'
              : 'table-name'
          "
          >{{ scope.title }}</span
        >
      </div>
      <div slot="number" slot-scope="scope" class="td-style td-center">
        <span class="table-number">{{ scope.parameter | changeNum }}</span>
      </div>
    </a-table>

    <div
      class="title-div"
      v-if="liveChartData && liveChartData.xData.length > 0"
    >
      <div>
        <svg-icon icon-class="top10" class="fs22" />
        <span class="title">最近10场直播</span>
      </div>
    </div>
    <div
      class="chart-div"
      v-if="liveChartData && liveChartData.xData.length > 0"
    >
      <my-chart
        :options="
          getBarOptions(
            liveChartData.xData,
            liveChartData.watch_count,
            liveChartData.interaction_count
          )
        "
        id="barChart"
      />
    </div>
    <Empty
      style="margin-top: 270px"
      :title="'暂无该平台官号'"
      v-if="!officialList || !officialList.author"
    />
  </div>
</template>

<script>
import * as vuex from "vuex";
import MyChart from "@/components/MyChart/MyChart";
import deepClone from "@/utils/clone-utils";
import barOptions from "../options/barOptions";
import Tooltip from "@/components/Tooltip/Tooltip";
import Empty from "@/components/Empty/Empty";
import WebpImage from "@/components/WebpImage/WebpImage";

const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardOfficial",
  data() {
    return {
      tooltipTitle: "粉丝总量为累计总数据，其他数据为本月数据。",
      columns: [
        {
          width: "130px",
          align: "left",
          slots: { title: "customTitleRanking" },
          scopedSlots: { customRender: "ranking" },
        },
        {
          title: "名称",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        {
          align: "center",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "number" },
        },
        // {
        //   title: "增降",
        //   width: "80px",
        //   align: "center",
        //   scopedSlots: { customRender: "increase" },
        // },
      ],
    };
  },
  components: {
    MyChart,
    Tooltip,
    Empty,
    WebpImage,
  },
  computed: {
    ...mapState({
      officialList: (state) => state.officialList, // 品牌官号排名
      liveChartData: (state) => state.liveChartData, // 直播top10
      officialLoading: (state) => state.officialLoading,
    }),
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions(["getBrandOfficialRanks", "getLiveTopTen"]),
    ...mapMutations(["changeState"]),
    initData() {
      // todoD
      let author_id =
        this.$parent.currentTab == "douyin"
          ? this.$parent.dy_author_id
          : this.$parent.currentTab == "kuaishou"
          ? this.$parent.ks_author_id
          : this.$parent.dcd_author_id;
      if (this.$parent.principal_id && (!author_id || author_id == "null")) {
        this.changeState({ data: null, name: "officialList" });
        this.changeState({ data: null, name: "liveChartData" });
        this.$message.warning(
          `当前品牌未绑定${
            this.$parent.currentTab == "douyin"
              ? "抖音号"
              : this.$parent.currentTab == "kuaishou"
              ? "快手号"
              : "懂车帝"
          }`
        );
        return false;
      }
      let params = {
        platform: this.$parent.currentTab,
        author_id: author_id,
      };
      this.getBrandOfficialRanks(params);
      this.getLiveTopTen(params);
    },
    //   查看更多跳转
    routerTo() {
      this.$router.push({
        path: "/brand/benchmarking",
      });
    },
    getBarOptions(xData, yData1, yData2) {
      console.log(yData2);
      if (!yData2 || yData2.length == 0) {
        return false;
      }
      // yData2 = yData2.map((item) => {
      //   return (item * 100).toFixed(0);
      // });
      let option = deepClone(barOptions);
      option.xAxis[0].data = xData;
      option.series[0].data = yData1;
      option.series[1].data = yData2;
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fs22 {
  font-size: 22px;
}
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 380px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .title {
      @include font_color("font_color20");
      font-size: 16px;
      margin-left: 10px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      .route {
        @include font_color("font_color28");
        font-size: 12px;
      }
      .arrow {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 10px;
        @include background_color("background_color5");
        @include font_color("font_color22");
        border-radius: 4px;
        transition: all 0.5s;
      }
    }
    .route-div:hover {
      .arrow {
        @include background_color("background_color1");
        @include font_color("font_color20");
      }
    }
  }
  .info-div {
    height: 68px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 29px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > img {
      width: 68px;
      height: 68px;
      border-radius: 16px;
      background: #fff;
    }
    .info-item {
      height: 40px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .font1 {
        @include font_color("font_color23");
        font-size: 12px;
      }
      .font2 {
        @include font_color("font_color20");
        font-size: 18px;
      }
    }
  }
  .table-style {
    .ranking > span {
      width: 28px;
      height: 20px;
      border-radius: 14px;
      display: inline-block;
      text-align: center;
      line-height: 20px;
    }
    .bc1 {
      @include background_color("background_color15");
      @include font_color("font_color25");
    }
    .bc2 {
      @include background_color("background_color18");
      @include font_color("font_color25");
    }
    .bc3 {
      @include background_color("background_color49");
      @include font_color("font_color25");
    }
    .bc {
      @include background_color("background_color51");
      @include font_color("font_color22");
    }
    .table-name {
      font-size: 14px;
      font-weight: bold;
      @include font_color("font_color21");
    }
    .ranking-color1 {
      @include font_color("font_color26");
    }
    .ranking-color2 {
      @include font_color("font_color29");
    }
    .ranking-color3 {
      @include font_color("font_color32");
    }
    .table-number {
      font-size: 14px;
      font-weight: bold;
      @include font_color("font_color26");
    }
  }
  .td-style {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .td-left {
    text-align: left;
  }
  .td-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .td-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color23 {
    @include font_color("font_color23");
  }
  .chart-div {
    width: 100%;
    height: 200px;
  }
}
</style>
