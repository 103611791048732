<template>
  <div class="component-wrap">
    <a-spin :spinning="weekLoading" class="spin-class" />
    <div class="title-div">
      <div @click="changeType('live')">
        <svg-icon
          :icon-class="type == 'live' ? 'live-list-active' : 'live-list'"
          class="fs22"
        />
        <span
          :class="type == 'live' ? 'font-color20 title' : 'font-color23 title'"
          >直播周榜</span
        >
      </div>
      <div @click="changeType('video')">
        <svg-icon
          :icon-class="type == 'video' ? 'video-list-active' : 'video-list'"
          class="fs22"
        />
        <span
          :class="type == 'video' ? 'font-color20 title' : 'font-color23 title'"
          >视频周榜</span
        >
      </div>
      <!-- <div @click="routerTo" class="route-div">
        <span class="route">查看更多</span>
        <span class="arrow">
          <svg-icon icon-class="arrow-right" style="font-size: 24px" />
        </span>
      </div> -->
    </div>

    <a-table
      :columns="columns"
      :data-source="type == 'live' ? liveRankList : awemeRankList"
      :rowKey="(data) => data.author_id"
      :pagination="false"
      class="table-style"
      :locale="locale"
    >
      <div slot="customTitle" style="display: flex; padding-left: 20px">
        <div>排名</div>
        <Tooltip
          :title="type == 'live' ? tooltipTitleLive : tooltipTitleVideo"
        />
      </div>
      <div
        slot="ranking"
        slot-scope="text, records, index"
        class="td-style td-left ranking"
      >
        <span
          style="margin: 0 12px 0 20px"
          :class="
            index == 0 ? 'bc1' : index == 1 ? 'bc2' : index == 2 ? 'bc3' : 'bc'
          "
          >{{ index + 1 }}</span
        >

        <svg-icon
          v-if="records.rank_diff > 0"
          icon-class="new-up"
          style="font-size: 12px"
        />
        <svg-icon
          v-if="records.rank_diff < 0"
          icon-class="new-down"
          style="font-size: 12px"
        />
        <svg-icon
          icon-class="NEW"
          v-if="records.is_new == 0"
          style="font-size: 30px"
        />
        <span class="font-color23">{{
          Math.abs(records.rank_diff) || ""
        }}</span>
      </div>
      <div slot="name" slot-scope="text, records, index" class="td-style table-name">
        <WebpImage
          :src="records.avatar"
          :width="'30px'"
          :height="'30px'"
          :radius="'50%'"
        />
        <span
          :class="
            index == 0
              ? 'ranking-color1'
              : index == 1
              ? 'ranking-color2'
              : index == 2
              ? 'ranking-color3'
              : ''
          "
          >{{ records.nickname || "-" }}</span
        >
      </div>
    </a-table>
  </div>
</template>

<script>
import * as vuex from "vuex";
import Tooltip from "@/components/Tooltip/Tooltip";
import WebpImage from "@/components/WebpImage/WebpImage";
import Empty from "@/components/Empty/Empty";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("brandSummary");
export default {
  name: "CardRanking",
  data() {
    return {
      type: "live",
      tooltipTitleLive: "按本周直播观看增量排名",
      tooltipTitleVideo: "按本周视频播放增量排名",
      columns: [
        {
          width: "130px",
          align: "left",
          slots: { title: "customTitle" },
          scopedSlots: { customRender: "ranking" },
        },
        {
          title: "平台昵称",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
      ],
      locale: {
        emptyText: <Empty style="margin-top: 20px;" title="暂无数据" />,
      },
    };
  },
  components: {
    Tooltip,
    WebpImage,
  },
  computed: {
    ...mapState({
      liveRankList: (state) => state.liveRankList, // 直播周榜
      awemeRankList: (state) => state.awemeRankList, // 视频周榜
      weekLoading: (state) => state.weekLoading,
    }),
  },
  created() {
    if (this.$parent.principal_id == "") {
      this.initData();
    }
  },
  methods: {
    ...mapActions(["getLiveRank", "getAwemeRank"]),
    ...mapMutations(["changeState"]),
    initData() {
      let params = {
        platform: this.$parent.currentTab,
      };
      this.getLiveRank(params);
      this.getAwemeRank(params);
    },
    changeType(type) {
      this.type = type;
      this.$emit('setPageTimer')
    },
    //   查看更多跳转
    routerTo() {
      if (this.type == "video") {
        this.$router.push({
          path: "/dealer/hotVideoList",
        });
      } else {
        this.$router.push({
          path: "/dealer/hotLiveList",
        });
      }
    },
    // 跳转作品发布
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            console.log(record);
            this.$router.push({
              path: "/dealer/production",
              query: {
                type: this.$parent.currentTab,
                author_id: record.author_id,
              },
            });
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.fs22 {
  font-size: 22px;
}
.component-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  .spin-class {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 137px 0;
    background: rgba(54, 57, 66, 0.5);
    z-index: 1000;
  }
  .title-div {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    > div {
      cursor: pointer;
      margin-right: 16px;
    }
    .title {
      font-size: 16px;
      margin-left: 10px;
    }
    .route-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      .route {
        @include font_color("font_color28");
        font-size: 12px;
      }
      .arrow {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-left: 10px;
        @include background_color("background_color5");
        @include font_color("font_color22");
        border-radius: 4px;
        transition: all 0.5s;
      }
    }
    .route-div:hover {
      .arrow {
        @include background_color("background_color1");
        @include font_color("font_color20");
      }
    }
  }
  .table-style {
    .ranking > span {
      width: 28px;
      height: 20px;
      border-radius: 14px;
      display: inline-block;
      text-align: center;
      line-height: 20px;
    }
    .table-name {
      font-size: 14px;
      @include font_color("font_color21");
      > span {
        margin-left: 10px;
        width: calc(100% - 80px);
        display: inline-block;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
    .bc1 {
      @include background_color("background_color15");
      @include font_color("font_color25");
    }
    .bc2 {
      @include background_color("background_color18");
      @include font_color("font_color25");
    }
    .bc3 {
      @include background_color("background_color49");
      @include font_color("font_color25");
    }
    .bc {
      @include background_color("background_color51");
      @include font_color("font_color22");
    }
  }
  .td-style {
    height: 42px;
    display: flex;
    align-items: center;
  }
  .td-left {
    text-align: left;
  }
  .td-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .td-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .font-color20 {
    @include font_color("font_color20");
  }
  .font-color21 {
    @include font_color("font_color21");
  }
  .font-color23 {
    @include font_color("font_color23");
  }
  .ranking-color1 {
    @include font_color("font_color26");
  }
  .ranking-color2 {
    @include font_color("font_color29");
  }
  .ranking-color3 {
    @include font_color("font_color32");
  }
}
</style>
