import dayjs from "dayjs"

export const platList = [
  {
    label: "抖音",
    value: "douyin"
  },
  {
    label: "快手",
    value: "kuaishou"
  }
]

export const defaultPrincipalInfo = {
  principal_image: '',
  principal_name: '--',
  official_author_count: 0,
  dealer_author_count: 0,
  kol_author_count: 0,
  active_author_count: 0,
  active_rate: 0,
  fans_count: 0,
  live_count: 0,
  aweme_count: 0,
  interaction_total: 0
}

export const btnList = [
  {
    label: "每日增量",
    value: "increase"
  },
  // {
  //   label: "累计总量",
  //   value: "total"
  // }  
]

const icon1 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAUCAYAAAD2rd/BAAAAAXNSR0IArs4c6QAAAbxJREFUSEvVl7FLQlEUxr9TpiQaQYNhRIFBNLRJQ0O5BkGFGITWaNAYKbW5FRaNQa4pQSIV9Ae8GhrCrSGChEqSHIJIMTTrxHv50MLivWcU767vnPP9+O45995H+IXFSX8L8s+TIJ4AwwnAXimbASEJpkNYWg/IGXlpVI4aLcCCdwpM6yA4fqzFSIE4QK7YfiOamoE5FGqC62oNhCUwlNUhMBgbEPqWKRR60wKuTKhOZRZ8YQABLaIA1skVDWrJ1QQstQFRotbZUpkRP83hKJlH6r4ksTg6jRh3WuAZtsJoqJGSnGa3lvZQDSwNWK5wUduz2ccyFiJZXN59gH5d/V1GbPltsLUbqp/EnraaB9QOonpgYdYD8J6sLDo7s5n5FlaOE6F3F+2fnQZNk2snrqY11AMf+6JgeGWR2MkTVhMPijRX3B3wjrRVYwkxGo36FCVXgoiFuTHgdRugbjWJcqzo7vlNUVHqYI9Jclnb4jTQPE8seG+1worCQ8FrFIqsiMFsIpyFexXF1g/itB6BddYSaveH/3voVAPr7ljT28Uh7oiurma5hXT1+JFc1tvzsuq0Th7wtafLX/4ivQN1Xv7ZoY+2ugAAAABJRU5ErkJggg=='
const icon2 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAUCAYAAAD2rd/BAAAAAXNSR0IArs4c6QAAAdxJREFUSEvVVz1IG2EYft5EwZ/TIIJEqYIWseLiINnjUp00FEWwkw7ZWyNuudvES9wz6KQgkaKdqovuksFFVEQFlRoEkTQXFTT3yqW9ckrE+y6K3K33vM/z3Pvzfe8RXuHhRKJcy6QHyYMBZu4BuOkvLf0mohTr+Cn5/KsUDt+VKkelElyrSigPVgF8fIHr0AuKVEWiK6VoOjbMsuzJVWMaRBPMbIuHiBjMseocpkiWdSfGbQkVI9ZUeYaBiENRVYrIkw5jxcOMNtAJP6yZvcvrWDk4w/pxGkcZrUDa5pPwudWPUPsHlHs9/4WMTHsYX5y0h3CGCwP253zX2rMX17f4vrmNg6ts0a9vr6tBPNiNhqoK6/tDqbaxU3QQhQ1n48oQdE6aykZmx9a2njVr4gzT832BR5kG03DNZHRZpMbChrWYssDMo6ZIcu8Es6l9W5rfejow/KnF2hqL0kT0q63gfyDKxZR+ZiQY3CwSaGLHf21h5zJjK7Sr3oe5/oAt7FMQgU6JECZNVU6cmjVIg0sbuLnP2zJRWebF5kivLWwxUMG06wy7riVE6/PuQydqODujDIFcdKy57uIwKuKqq9lsIVctP4Zp162XZqZds8BbT5fCIGrpQeK3/0V6AI5oHn4YuzPPAAAAAElFTkSuQmCC'
const icon3 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAUCAYAAAD2rd/BAAAAAXNSR0IArs4c6QAAAcVJREFUSEvVl80rBGEcx7+/WS/FhqSwUUqJknVYN8XVxUvCgfNujrusHOcoq92jZs8ckLz8BZSbPexsipQS26IktJSXnZ9mNaxammdWNM91fs/v++n7fJ+XIfzCULyxYjhLBgnaADM8ALne23KKCDGGtIX086Yv6nkpVI4KbRD1x4eYOQSi5h97MZ8QUdAb6dwoRNMysCyzVH+XmCNgmsGm+hCIGVi4qOyYlWXSrICbEsrXWPGr8wAHrYgCFPJF3DNW5loC1mMA0HqusxntFQepPRxd7uPm4SLLUl1ej9a6LrS7uuGQij74dKcBHrYSD2Hg7AYrcxzmZjb9dIttdRHX6WRe02qcDeh3T8JZWvX5nfkEj5k20Y0oDhxIjIC1VUNZd3YlFvoW1qjTocc8wS9Og6RRX7hjTSQawsDRQHyJGeOGiJrcwe6xOc2elhG4G3o/o0FY9oY7J4SAo1OJPtZYAbhRZKJRq7t7dX9qamptRVPWZWuDzkkiHyl+9cwqrC68uBvAS+bJFEOxoxSTPWFTtfmL6Nx+wLaLhOj6/PumEwVW7Has2e7i0FfEVlezESFbPX50aNs9Lw2nbfOAzz1d/vIX6Q2YhRXuLFbEgQAAAABJRU5ErkJggg=='
const icon4 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAUCAYAAAD2rd/BAAAAAXNSR0IArs4c6QAAAd5JREFUSEvVl08oBHEUx79v2fxptVISUeT/xWnlQubg4oS0LqRcyJUdicteCLO5iosSFxJOLg4jLrKnvSyLyIokZbP5E+ZpNrutLM3Mkmau8977fnq/7/v9IfzC5/X2WsM2eytZqIWZHQAKPspeEpGXFd6whUPrDsfcS7JylGwB+dDVxiCJgNKfajFwQmBRqPSsJaNpGNjNbotwFJ4gkIsZmuoQgRnskcttw25yK0bANQklKiwHXFNgEo2IglgSKjxDRnINAas2IKLV+M6+vijY3brG/s4Nri4eIyz5hRmobchFfVMeUq2WGF+k08ztRuyhG1gdsPssuz/es3e3z5iZ9OPi7CFh0wqLM9E/XI3snLTYf9XTWfehar2DqBtYPhKdULAcVVY7K436voWNxqnQ4ljNp06D0SFUSSt6rKEbeDswtMjMnVERefMKK/OnmjSdPSUQmvPjrEFLjRVTXZqSP4JIPhxsBllmwSjSkxiNlUZ8ODsOa0otLrNBHK/RFPsliBAEK30kB8Rzo7Bq0YHuPTw/vWmCSEtPwfRCnabYhEGEoAmBzWYJvevz70OnF1g+EJ0gE21rpjs41BUx1dEcOzDMdPlRoU13vYx12iwX+PjdJfJEsttbif/+ifQOXMEesKvS9G8AAAAASUVORK5CYII='
const icon5 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAUCAYAAAD2rd/BAAAAAXNSR0IArs4c6QAAActJREFUSEvVVzlLA2EQfbN4BcRoRDzwQK0CNoJEEPwB23ggJoIWVlrYejay2CQxthZaWSh4IB7Nai0IimAjpFIxEQ/EIyLEI+zIBhdXiLi7EWW/dmfee/tm5jsIv7D6DmYyYzd3bQyhFeAGAGUfsBcAHRCUDWeRa322of8tXTpKF6BL9rcroBCA2h+wjgXw0KI4tpYOp2XBkiQJ4UZHgAmDYDaGQ8TEmHLvxUclSVKsCDdGlALZJwcnGTxkjZRCS+LIsLVcC1nJNiBhVe8sJxTc7Z8hdniOl+unJGp2cS6c9eVweapAGcInExELrHRYaQ/TDqsD9nBzH9b37FssjsjcPp4vH1P+fk5pHip7Pch0OvTfj/OLCtxmB9G0YJ/s72TQssasOnsyvfOtWC1OFV0z0PzFaQJ7l8SxFTNFNi3YKwfnAe7WSG53T3G1eWSIs6SlDoVN1bpYWlgWR3oMJX8EkW87IDLTDJgrzCRqsaq78eiDoVRHRX7SZUuLKErE/eTdCkasilWJw+MylNeEIQ1CVgbcE6Kh2JRBqmjbCbZdS5itz78PnVnBttvWbHdwqBWx1dGstZCtLj+qaNtdLzWnbXOB1+8uf/lEegd+2RTYzCfE/QAAAABJRU5ErkJggg=='
const icon6 = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAUCAYAAAD2rd/BAAAAAXNSR0IArs4c6QAAAc9JREFUSEvVlz9IW1EUh79jmmIoCAlmEQVbC1Khm0OmdlIoNFjpn6WlW3WrGapIsmRJKY1g4pZ0K7pYRYOd6tRODt0KlkKj0pQOTVEISIoaj7yQZ1Ma6Xsvory33t85v49zz7n3PuEUvpGMeoNl7iAMKfQDHbW0PwQ+ouSKPpazo7LfrJ00myCW1mGFJNDzn1x5gfHEmCw14+kYOB7Xlr0AL4BnKNbyCApMXdxmMh6XQyfg1owaZI7O6EuUcSemCMnnT2XCSawj4GobCIv1lT2s7LP1Jcf3/DtKO5tVljb/ZTp7BunuHaLF4/3DJ6god520h21gY8Daf/O5vmfLuz9ZW52gtP21YdHaAlcJDSTxXQrWr+d/tXLN7iDaBo6l9L4K86azUdn3K09OhDV1BvTN8Ku/Ki3Kg0RE3thpDfvAaZ1VeGiabKwv8GktZcnzeijClb57x1qBucSYPLIUXBNJdFpv4SGD0mUn0NR+WBlhp7huKdQf7ONGOGtJ+49IKFBhVKIz+s0prJH07esBKgdlSxCeCz5uP161pG0oEgouBHZbS9jdn9h5D51tYLcda667OIwdcdXVbLaQqx4/BrTrnpdmpV3zgK8/Xc7yF+kIbK74kY3v2REAAAAASUVORK5CYII='

const color1 = ['#FFCA5C', '#F38E7E', '#9971D0']
const color2 = ['#C0D975', '#FFCA5C', '#F38E7E', '#56B788', '#7A91FF']
const color3 = ['#C0D975', '#FFCA5C', '#F38E7E', '#56B788']

const options = {
  legend: {
    left: 0,
    right: 0,
    padding: [2, 7],
    itemGap: 30,
    itemWidth: 22,
    itemHeight: 10,
    data: []
  },
  grid: {
    top: 66,
    right: 0,
    bottom: 0,
    left: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      formatter: function (value) {
        return dayjs(value).format('MM/DD')
      },
      color: '#9CA8BA',
      fontSize: 12
    },
    data: []
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: false
    },
    axisLabel: {
      formatter: function (value) {
        let str = ''

        if (Math.abs(value) > 10000) {
          str = `${value / 10000}w`
        } else {
          str = `${value}`
        }

        return str
      },
      color: '#9CA8BA',
      fontSize: 12
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.04)'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: 'rgba(255, 255, 255, .1)'
      }
    },
    formatter: function (params) {
      console.log(params)
      const icons = [
        { name: '视频发布', icon: icon1 },
        { name: '直播场次', icon: icon2 },
        { name: '粉丝量', icon: icon3 },
        { name: '视频互动量', icon: icon4 },
        { name: '播放量', icon: icon1},
        { name: '获赞数', icon: icon2 },
        { name: '评论数', icon: icon5 },
        { name: '转发数', icon: icon6 },
        { name: '直播互动量', icon: icon4 },
        { name: '观看人次', icon: icon1 },
        { name: '观看人数', icon: icon1 }
      ]
      let htmlList = []
      params.forEach(param => {
        const index = icons.findIndex(icon => icon.name === param.seriesName)
        htmlList.push(
          `
            <div class="tooltip-item">
              <img class="icon" src="${icons[index].icon.replace('image://', '')}"></img>
              <span class="label">${param.seriesName}：</span>
              <span class="value">${param.value}</span>
            </div>
          `
        )
      })

      return `
        <div class="tooltip">
          <div class="tooltip__head">${params[0].name}</div>
          <div class="tooltip__body">${htmlList.join('')}</div>
        </div>
      `
    },
    backgroundColor: 'transparent',
    padding: 0
  },
  series: []
}

export const options1 = {
  ...options,
  legend: {
    ...options.legend,
    data: [
      { name: '视频发布', icon: icon1, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '直播场次', icon: icon2, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '粉丝量', icon: icon3, textStyle: { color: '#9CA8BA', fontSize: 12 } }  
    ]
  },
  series: [
    {
      name: '视频发布',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '直播场次',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '粉丝量',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    }
  ],
  color: [...color1]
}

export const options2 = {
  ...options,
  legend: {
    ...options.legend,
    data: [
      { name: '视频互动量', icon: icon4, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '播放量', icon: icon1, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '获赞数', icon: icon2, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '评论数', icon: icon5, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '转发数', icon: icon6, textStyle: { color: '#9CA8BA', fontSize: 12 } }
    ]
  },
  series: [
    {
      name: '视频互动量',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '播放量',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '获赞数',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '评论数',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '转发数',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    }
  ],
  color: [...color2]
}

export const options3 = {
  ...options,
  legend: {
    ...options.legend,
    data: [
      { name: '直播互动量', icon: icon4, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '观看人次', icon: icon1, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '获赞数', icon: icon2, textStyle: { color: '#9CA8BA', fontSize: 12 } },
      { name: '评论数', icon: icon5, textStyle: { color: '#9CA8BA', fontSize: 12 } }
    ]
  },
  series: [
    {
      name: '直播互动量',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '观看人次',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '获赞数',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    },
    {
      name: '评论数',
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        opacity: 0
      },
      emphasis: {
        itemStyle: {
          color: '#2B2F3B',
          borderWidth: 2,
          opacity: 1
        }
      },
      smooth: true,
      data: []
    }
  ],
  color: [...color3]
}
