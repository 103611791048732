/*
<platform-header title="商务政策考核" :tabData="tabData" @tabChange="tabChange" />
*/
<template>
  <div class="platform-container">
    <div class="title">{{title}}</div>

    <div class="tab-btn-wrap">
      <a-button
        v-for="(item, index) in tabData"
        :key="'btn' + index"
        round
        :class="
          currentClick == item.value
            ? 'active-btn-class btn-class'
            : 'btn-class'
        "
        :style="defaultBtnStyle"
        @click="tabClick(item.value)"
      >
        <svg-icon :icon-class="item.value" v-if="!item.isHideIcon" class="icon"></svg-icon>
        <span>{{ item.label }}</span>
        <!-- todo -->
        <!-- <div v-if="item.value == 'dongchedi'" class="beta">beta</div> -->
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformHeader",
  props: {
    tabData: {
      type: Array,
    },
    title: {
      type: String,
      default: ' '
    },
    defaultBtnStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currentClick: this.tabData[0].value,
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    tabClick(value) {
      this.currentClick = value;
      this.$emit("tabChange", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-container {
  padding: 50px 60px 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 30px;
    @include font_color("font_color20");
  } 
}
.btn-class.active-btn-class {
  @include background_color("background_color13");
  @include font_color("font_color25");
  font-weight: bold;
}
.btn-class {
  cursor: pointer;
  @include font_color("font_color22");
  min-width: 114px;
  width: 114px;
  height: 40px;
  border: none;
  line-height: 30px;
  padding: 5px 10px;
  border-radius: 0px !important;
  @include background_color("background_color2");
}
.btn-class:first-child {
  border-radius: 8px 0 0 8px !important;
}
.btn-class:last-child {
  border-radius: 0 8px 8px 0 !important;
  border-right: 1px solid rgba(0, 0, 0, 0);
}

.tab-btn-wrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-class {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px !important;
    & + .btn-class {
      margin-left: 10px;
    }
    .icon {
      margin-right: 4px;
      display: inline-block;
      font-size: 24px;
      @include font_color("font_color23");
    }
    &.active-btn-class {
      .icon {
        @include font_color("font_color25");
      }
    }
  }
}
.beta {
  width: 36px;
  height: 14px;
  border-radius: 7px;
  background: #ff4160;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 14px;
  position: absolute;
  top: -6px;
  right: -12px;
}
</style>
