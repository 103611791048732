<template>
  <div style="width: 100%; height: 100vh; overflow: hidden">
    <div class="window-min-width">
      <platform-header
        title="品牌概览"
        :tabData="tabData"
        @tabChange="tabChange"
      />
      <div class="main-container">
        <div class="top-container">
          <div class="top-container-left">
            <card-ranking ref="cardRanking"></card-ranking>
          </div>
          <div class="top-container-right">
            <card-area ref="cardArea"></card-area>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import MyChart from "@/components/MyChart/MyChart";
import CardRanking from "./components/CardRanking";
import CardArea from "./components/CardArea";

export default {
  name: "brandSummary",
  components: {
    PlatformHeader,
    MyChart,
    CardRanking,
    CardArea,
  },
  data() {
    return {
      msg: "品牌概览1",
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        }
      ],
      currentTab: "douyin",
      principal_id: "",
      dy_author_id: "",
      ks_author_id: "",
      dcd_author_id: "",
      pageRouterTimer: null,
    };
  },
  computed: {},
  created() {
    this.setPageTimer()
    console.log("本品概述入口", this.$route.query);
    this.$route.query.principal_id &&
      (this.principal_id = this.$route.query.principal_id);
    this.$route.query.dy_author_id &&
      (this.dy_author_id = this.$route.query.dy_author_id);
    this.$route.query.ks_author_id &&
      (this.ks_author_id = this.$route.query.ks_author_id);
    this.$route.query.dcd_author_id &&
      (this.dcd_author_id = this.$route.query.dcd_author_id);
  },
  watch: {},
  methods: {
    tabChange(val) {
      console.log("切换平台", val);
      this.currentTab = val;
      this.setPageTimer()
      this.initAllData();
    },
    initAllData() {
      this.$refs.cardRanking.initData();
      this.$refs.cardArea.initData();
    },
    // 重置定时器
    setPageTimer() {
      clearTimeout(this.pageRouterTimer)
      this.pageRouterTimer = null;
      this.pageRouterTimer = setTimeout(() => {
        this.pageRouter();
      }, 1000 * 20);
    },
    // 定时路由跳转
    pageRouter() {
      clearTimeout(this.pageRouterTimer)
      this.pageRouterTimer = null;
      this.$router.push(`/bcPublicScreen/brandSummary2`);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.window-min-width {
  min-width: 1440px;
}
.main-container {
  padding: 0 60px;
  .top-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    &-left {
      @include background_color("background_color2");
      width: 388px;
      height: 896px;
      border-radius: 16px;
      overflow: hidden;
    }
    &-right {
      @include background_color("background_color2");
      width: 1392px;
      height: 896px;
      border-radius: 16px;
      overflow: hidden;
    }
  }
}
.fw {
  font-weight: bold;
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
</style>
