export const platList = [
  {
    label: '抖音',
    value: 'douyin'
  },
  {
    label: '快手',
    value: 'kuaishou'
  }
]
