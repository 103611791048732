var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('a-spin',{staticClass:"spin-class",attrs:{"spinning":_vm.officialLoading}}),_c('div',{staticClass:"title-div"},[_c('div',[_c('svg-icon',{staticClass:"fs22",attrs:{"icon-class":"brand-official"}}),_c('span',{staticClass:"title"},[_vm._v("品牌官号")])],1)]),(_vm.officialList && _vm.officialList.author)?_c('div',{staticClass:"info-div"},[_c('WebpImage',{attrs:{"src":_vm.officialList.author.avatar,"width":'68px',"height":'68px',"radius":'16px'}}),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"font2"},[_vm._v(_vm._s(_vm.officialList.author.nickname))]),_c('div',{staticClass:"font1"},[_vm._v(" "+_vm._s(_vm.$parent.currentTab == "douyin" ? "抖音" : _vm.$parent.currentTab == "kuaishou" ? "快手" : _vm.$parent.currentTab == "dongchedi" ? "懂车帝" : "平台")+"号: "+_vm._s(_vm.officialList.author.code)+" ")])])],1):_vm._e(),(_vm.officialList && _vm.officialList.author)?_c('a-table',{staticClass:"table-style",attrs:{"columns":_vm.columns,"data-source":_vm.officialList.list,"rowKey":(data) => data.title,"pagination":false,"locale":{ emptyText: '暂无数据' }},scopedSlots:_vm._u([{key:"ranking",fn:function(scope){return _c('div',{staticClass:"td-style td-left ranking"},[_c('span',{class:scope.ranking == 1
            ? 'bc1'
            : scope.ranking == 2
            ? 'bc2'
            : scope.ranking == 3
            ? 'bc3'
            : 'bc',staticStyle:{"margin":"0 12px 0 20px"}},[_vm._v(_vm._s(scope.ranking))]),(scope.ranking_over_last_month > 0 && scope.ranking < 1000)?_c('svg-icon',{staticStyle:{"font-size":"12px"},attrs:{"icon-class":"new-up"}}):_vm._e(),(scope.ranking_over_last_month < 0 && scope.ranking < 1000)?_c('svg-icon',{staticStyle:{"font-size":"12px"},attrs:{"icon-class":"new-down"}}):_vm._e(),(scope.ranking < 1000)?_c('span',{staticClass:"font-color23"},[_vm._v(_vm._s(Math.abs(scope.ranking_over_last_month) || ""))]):_vm._e()],1)}},{key:"name",fn:function(scope){return _c('div',{staticClass:"td-style"},[_c('span',{class:scope.ranking == 1
            ? 'table-name ranking-color1'
            : scope.ranking == 2
            ? 'table-name ranking-color2'
            : scope.ranking == 3
            ? 'table-name ranking-color3'
            : 'table-name'},[_vm._v(_vm._s(scope.title))])])}},{key:"number",fn:function(scope){return _c('div',{staticClass:"td-style td-center"},[_c('span',{staticClass:"table-number"},[_vm._v(_vm._s(_vm._f("changeNum")(scope.parameter)))])])}}],null,false,2979375647)},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('div',[_vm._v("数据")]),_c('Tooltip',{attrs:{"title":_vm.tooltipTitle}})],1),_c('div',{staticStyle:{"padding-left":"20px"},attrs:{"slot":"customTitleRanking"},slot:"customTitleRanking"},[_c('div',[_vm._v("排名")])])]):_vm._e(),(_vm.liveChartData && _vm.liveChartData.xData.length > 0)?_c('div',{staticClass:"title-div"},[_c('div',[_c('svg-icon',{staticClass:"fs22",attrs:{"icon-class":"top10"}}),_c('span',{staticClass:"title"},[_vm._v("最近10场直播")])],1)]):_vm._e(),(_vm.liveChartData && _vm.liveChartData.xData.length > 0)?_c('div',{staticClass:"chart-div"},[_c('my-chart',{attrs:{"options":_vm.getBarOptions(
          _vm.liveChartData.xData,
          _vm.liveChartData.watch_count,
          _vm.liveChartData.interaction_count
        ),"id":"barChart"}})],1):_vm._e(),(!_vm.officialList || !_vm.officialList.author)?_c('Empty',{staticStyle:{"margin-top":"270px"},attrs:{"title":'暂无该平台官号'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }