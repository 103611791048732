var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('a-spin',{staticClass:"spin-class",attrs:{"spinning":_vm.weekLoading}}),_c('div',{staticClass:"title-div"},[_c('div',{on:{"click":function($event){return _vm.changeType('live')}}},[_c('svg-icon',{staticClass:"fs22",attrs:{"icon-class":_vm.type == 'live' ? 'live-list-active' : 'live-list'}}),_c('span',{class:_vm.type == 'live' ? 'font-color20 title' : 'font-color23 title'},[_vm._v("直播周榜")])],1),_c('div',{on:{"click":function($event){return _vm.changeType('video')}}},[_c('svg-icon',{staticClass:"fs22",attrs:{"icon-class":_vm.type == 'video' ? 'video-list-active' : 'video-list'}}),_c('span',{class:_vm.type == 'video' ? 'font-color20 title' : 'font-color23 title'},[_vm._v("视频周榜")])],1)]),_c('a-table',{staticClass:"table-style",attrs:{"columns":_vm.columns,"data-source":_vm.type == 'live' ? _vm.liveRankList : _vm.awemeRankList,"rowKey":(data) => data.author_id,"pagination":false,"locale":_vm.locale},scopedSlots:_vm._u([{key:"ranking",fn:function(text, records, index){return _c('div',{staticClass:"td-style td-left ranking"},[_c('span',{class:index == 0 ? 'bc1' : index == 1 ? 'bc2' : index == 2 ? 'bc3' : 'bc',staticStyle:{"margin":"0 12px 0 20px"}},[_vm._v(_vm._s(index + 1))]),(records.rank_diff > 0)?_c('svg-icon',{staticStyle:{"font-size":"12px"},attrs:{"icon-class":"new-up"}}):_vm._e(),(records.rank_diff < 0)?_c('svg-icon',{staticStyle:{"font-size":"12px"},attrs:{"icon-class":"new-down"}}):_vm._e(),(records.is_new == 0)?_c('svg-icon',{staticStyle:{"font-size":"30px"},attrs:{"icon-class":"NEW"}}):_vm._e(),_c('span',{staticClass:"font-color23"},[_vm._v(_vm._s(Math.abs(records.rank_diff) || ""))])],1)}},{key:"name",fn:function(text, records, index){return _c('div',{staticClass:"td-style table-name"},[_c('WebpImage',{attrs:{"src":records.avatar,"width":'30px',"height":'30px',"radius":'50%'}}),_c('span',{class:index == 0
            ? 'ranking-color1'
            : index == 1
            ? 'ranking-color2'
            : index == 2
            ? 'ranking-color3'
            : ''},[_vm._v(_vm._s(records.nickname || "-"))])],1)}}])},[_c('div',{staticStyle:{"display":"flex","padding-left":"20px"},attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('div',[_vm._v("排名")]),_c('Tooltip',{attrs:{"title":_vm.type == 'live' ? _vm.tooltipTitleLive : _vm.tooltipTitleVideo}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }